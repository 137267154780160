export const googleSignInUrl = (return_url: string | null) => {
  const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

  const options = {
    redirect_uri: `${process.env.REACT_APP_BACKEND_URL}/api/v1/oauth/google`,
    client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string,
    access_type: "offline",
    response_type: "code",
    prompt: "consent",
    scope: [
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
    ].join(" "),
    state: return_url || "",
  };

  const queryParams = new URLSearchParams(options).toString();

  return `${rootUrl}?${queryParams}`;
};

export const linkedinSignInUrl = (return_url: string | null) => {
  const rootUrl = `https://www.linkedin.com/oauth/v2/authorization`;

  const options = {
    redirect_uri: `${process.env.REACT_APP_BACKEND_URL}/api/v1/oauth/linkedin`,
    client_id: process.env.REACT_APP_LINKEDIN_OAUTH_CLIENT_ID as string,
    scope: ["openid", "email", "profile"].join(","),
    response_type: "code",
    state: return_url || "",
  };

  const queryParams = new URLSearchParams(options).toString();

  return `${rootUrl}?${queryParams}`;
};
