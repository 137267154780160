import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../app/store";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { destroyProfile, getProfiles } from "../redux/profile/profileActions";
import { profileSelector } from "../redux/profile/profileSlice";
import { toast } from "react-toastify";
import { Edit, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import urls from "../utils/urls";
import usePermission from "../hooks/usePermission";
import { texts } from "../utils/texts";

function Profiles() {
  const dispatch = useAppDispatch();
  const { width, height } = useWindowDimensions();
  const tableRef = useRef<HTMLDivElement>(null);

  const hasPermissionToProfilesShow = usePermission("profiles.show");
  const hasPermissionToProfilesCreate = usePermission("profiles.create");
  const hasPermissionToProfilesUpdate = usePermission("profiles.update");
  const hasPermissionToProfilesDestroy = usePermission("profiles.destroy");

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<GridRowId | null>(null);
  const [offsetTop, setOffsetTop] = useState<number>(0);

  const profileState = useAppSelector(profileSelector);

  useEffect(() => {
    if (tableRef.current) {
      setOffsetTop(tableRef.current.offsetTop);
    }
  }, [tableRef.current, width, height]);

  useEffect(() => {
    dispatch(getProfiles());
  }, []);

  const handleOpenDeleteDialog = (id: GridRowId) => {
    setIdToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setIdToDelete(null);
  };

  const handleDelete = async () => {
    if (idToDelete) {
      await dispatch(destroyProfile(idToDelete.toString())).then((value) => {
        if (value.meta.requestStatus !== "rejected") {
          toast.success("Benefício excluído com sucesso");
          dispatch(getProfiles());
        } else {
          toast.error(profileState.errorMessage);
        }
      });
    }
    handleCloseDeleteDialog();
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const actions = [
          <Tooltip
            key={`edit-${id}`}
            title={
              hasPermissionToProfilesShow || hasPermissionToProfilesUpdate
                ? "Atualizar"
                : texts.notAuthorized
            }
          >
            <Box>
              <GridActionsCellItem
                icon={
                  <Link to={urls.manageProfile.replace(":id", id.toString())}>
                    <Edit />
                  </Link>
                }
                label="Edit"
                className="textPrimary"
                disabled={
                  !hasPermissionToProfilesShow && !hasPermissionToProfilesUpdate
                }
                // color="primary"
              />
            </Box>
          </Tooltip>,
        ];

        if (row.editable) {
          actions.push(
            <Box key={`delete-${id}`}>
              <Tooltip
                title={
                  hasPermissionToProfilesDestroy
                    ? "Excluir"
                    : texts.notAuthorized
                }
              >
                <Box>
                  <GridActionsCellItem
                    icon={<Delete />}
                    label="Delete"
                    onClick={() => handleOpenDeleteDialog(id)}
                    disabled={!hasPermissionToProfilesDestroy}
                    // color="error"
                  />
                </Box>
              </Tooltip>
              <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Confirmar exclusão</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Tem certeza de que deseja excluir este perfil?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="outlined" onClick={handleCloseDeleteDialog}>
                    Cancelar
                  </Button>
                  <LoadingButton
                    variant="contained"
                    onClick={handleDelete}
                    color="error"
                    loading={profileState.isDestroying}
                  >
                    Excluir
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            </Box>
          );
        }

        return actions;
      },
    },
  ];

  return (
    <Box sx={{ height: `calc(100% - ${offsetTop}px)` }}>
      <Typography variant="h5">Perfis</Typography>
      <Box sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "flex-end" }}>
        <Tooltip
          title={!hasPermissionToProfilesCreate ? texts.notAuthorized : ""}
        >
          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              size="large"
              disabled={!hasPermissionToProfilesCreate}
            >
              <Link to={urls.newProfile}>Adicionar novo perfil</Link>
            </LoadingButton>
          </Box>
        </Tooltip>
      </Box>

      <Box style={{ height: "100%", width: "100%", minWidth: 0 }}>
        <DataGrid
          ref={tableRef}
          rows={profileState.profiles}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick
          autoHeight
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within":
              {
                outline: "none !important",
              },
          }}
          loading={profileState.isFetching}
        />
      </Box>
    </Box>
  );
}

export default Profiles;
