import React, { useEffect, useRef } from "react";
import {
  Stepper as StepperCore,
  StepperProps,
  Step,
  StepButton,
  StepLabel,
  Box,
} from "@mui/material";
import { FormikProps } from "formik";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useAppSelector } from "../app/store";
import { navSelector } from "../redux/nav/navSlice";

type Fields = string[];

export interface Steps {
  name: string;
  fields: Fields;
  requiredFields: Fields;
  onLeaveCurrentStep?: (formik: FormikProps<any>) => void;
}

interface IStepper extends StepperProps {
  steps: Steps[];
  stepSaved: number | null;
  formik: FormikProps<any>;
  onStepChange: (step: number) => void;
}

const Stepper: React.FC<IStepper> = ({
  steps,
  stepSaved,
  formik,
  onStepChange,
  ...props
}) => {
  const { width } = useWindowDimensions();

  const navState = useAppSelector(navSelector);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    adjustWidth();
  }, [width, containerRef?.current]);

  useEffect(() => {
    setTimeout(() => {
      adjustWidth();
    }, 225);
  }, [navState.open]);

  const adjustWidth = () => {
    if (containerRef?.current) {
      containerRef.current.style.width = `unset`;
      containerRef.current.style.width = `${containerRef.current.offsetWidth}px`;
    }
  };

  const handleStep = (step: number) => () => {
    if (props.activeStep && steps[props.activeStep]?.onLeaveCurrentStep) {
      // @ts-expect-error: it will never be undefined
      steps[props.activeStep]?.onLeaveCurrentStep(formik);
    }

    onStepChange(step);
  };

  const isCompleted = (step: Steps, index: number) => {
    let completed = true;
    step.requiredFields.forEach((field) => {
      if (!formik.values[field]) {
        completed = false;
      }
    });
    return !!(completed && stepSaved && index <= stepSaved);
  };

  const hasError = (fields: Fields) => {
    return fields.some(
      (item) => formik?.errors && Object.keys(formik.errors).includes(item)
    );
  };

  return (
    <Box ref={containerRef} sx={{ overflow: "auto hidden" }}>
      <StepperCore {...props}>
        {steps.map((step, index) => (
          <Step
            key={step.name}
            completed={isCompleted(step, index)}
            sx={{ paddingRight: steps.length - 1 === index ? "16px" : "8px" }}
          >
            <StepButton color="inherit" onClick={handleStep(index)}>
              <StepLabel error={hasError(step.fields)}>{step.name}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </StepperCore>
    </Box>
  );
};

export default Stepper;
