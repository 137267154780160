import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../utils/localStorage";
import urls from "../utils/urls";
import { useAppSelector } from "../app/store";
import { userSelector } from "../redux/user/userSlice";

const PublicOnlyRoute = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();

  const { currentUser } = useAppSelector(userSelector);

  useEffect(() => {
    const token = getToken();
    if (token) {
      if (!currentUser.company) {
        navigate(urls.adminCandidateJobs);
      } else {
        navigate(urls.dashboard);
      }
    }
  }, []);

  return <>{children}</>;
};

export default PublicOnlyRoute;
