import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Divider,
  Slider,
  Box,
  Typography,
  IconButton,
  useTheme,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Cropper, { CropperProps } from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";
import { ZoomIn, ZoomOut } from "@mui/icons-material";

interface ICropperModal {
  title: string;
  open: boolean;
  image?: string;
  cropperProps?: Partial<CropperProps>;
  onClose: () => void;
  onCrop: (file: File) => void;
}

const CropperModal: React.FC<ICropperModal> = ({
  title,
  open,
  image,
  cropperProps,
  onClose,
  onCrop,
}) => {
  const theme = useTheme();

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const zoomMin = 0.5;
  const zoomMax = 3;

  const onApply = async () => {
    if (image && croppedAreaPixels) {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      if (croppedImage) {
        onCrop(croppedImage);
      }
    }
    setZoom(zoomMin);
  };

  const onCropChange = (crop: Point) => {
    setCrop(crop);
  };

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom: number) => {
    setZoom(zoom);
  };

  const onZoomOut = () => {
    const newZoom = Number(zoom - 0.1);
    if (zoomMin <= newZoom) {
      setZoom(newZoom);
    } else {
      setZoom(zoomMin);
    }
  };

  const onZoomIn = () => {
    const newZoom = Number(zoom + 0.1);
    if (newZoom <= zoomMax) {
      setZoom(newZoom);
    } else {
      setZoom(zoomMax);
    }
  };

  const createImage = (url: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.src = url;
    });
  };

  const getCroppedImg = async (
    imageSrc: string,
    pixelCrop: Area,
    flip = { horizontal: false, vertical: false }
  ): Promise<File | null> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    // set canvas size to match the bounding box
    canvas.width = image.width;
    canvas.height = image.height;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(image.width / 2, image.height / 2);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement("canvas");

    const croppedCtx = croppedCanvas.getContext("2d");

    if (!croppedCtx) {
      return null;
    }

    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(
      canvas,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      croppedCanvas.toBlob((blob) => {
        if (blob) {
          resolve(new File([blob], "image", { type: "image/png" }));
        }
      }, "image/png");
    });
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <Box sx={{ position: "relative", width: "100%", height: "600px" }}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={1}
          showGrid={false}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
          {...cropperProps}
        />
      </Box>
      <Box
        sx={{
          my: 2,
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Typography>Zoom</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            edge="start"
            sx={{ mr: 1, color: theme.palette.grey[600] }}
            onClick={() => onZoomOut()}
          >
            <ZoomOut />
          </IconButton>
          <Slider
            value={zoom}
            min={zoomMin}
            max={zoomMax}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(Number(zoom))}
          />
          <IconButton
            edge="start"
            sx={{ ml: 1, color: theme.palette.grey[600] }}
            onClick={() => onZoomIn()}
          >
            <ZoomIn />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <DialogActions>
        <LoadingButton variant="outlined" onClick={onClose}>
          Cancelar
        </LoadingButton>
        <LoadingButton variant="contained" onClick={onApply}>
          Aplicar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CropperModal;
