import React from "react";
import { FormQuestion } from "../utils/types";
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { FormikProps, getIn } from "formik";
import { DatePicker } from "@mui/x-date-pickers";

interface IQuestion {
  question: FormQuestion;
  formik: FormikProps<any>;
  index: number;
}

const QuestionInput: React.FC<IQuestion> = ({ question, formik, index }) => {
  if (question.type === "short_answer") {
    return (
      <TextField
        type="text"
        label={question.name}
        size="small"
        required={question.option === "required"}
        onChange={(e) => {
          formik.setFieldValue(`form.questions.${index}.value`, e.target.value);
        }}
        onBlur={formik.handleBlur(`form.questions.${index}.value`)}
        value={getIn(formik.values, `form.questions.${index}.value`)}
        error={
          !!(
            getIn(formik.touched, `form.questions.${index}.value`) &&
            getIn(formik.errors, `form.questions.${index}.value`)
          )
        }
        helperText={
          getIn(formik.touched, `form.questions.${index}.value`) &&
          getIn(formik.errors, `form.questions.${index}.value`)
            ? getIn(formik.errors, `form.questions.${index}.value`)
            : null
        }
      />
    );
  } else if (question.type === "long_answer") {
    return (
      <TextField
        type="text"
        label={question.name}
        size="small"
        multiline
        rows={4}
        required={question.option === "required"}
        onChange={(e) => {
          formik.setFieldValue(`form.questions.${index}.value`, e.target.value);
        }}
        onBlur={formik.handleBlur(`form.questions.${index}.value`)}
        value={getIn(formik.values, `form.questions.${index}.value`)}
        error={
          !!(
            getIn(formik.touched, `form.questions.${index}.value`) &&
            getIn(formik.errors, `form.questions.${index}.value`)
          )
        }
        helperText={
          getIn(formik.touched, `form.questions.${index}.value`) &&
          getIn(formik.errors, `form.questions.${index}.value`)
            ? getIn(formik.errors, `form.questions.${index}.value`)
            : null
        }
      />
    );
  } else if (question.type === "yes_no") {
    return (
      <>
        <FormLabel>{question.name}</FormLabel>
        <RadioGroup
          value={getIn(formik.values, `form.questions.${index}.value`)}
          onChange={(e, checked) => {
            formik.setFieldValue(`form.questions.${index}.value`, checked);
            formik.setFieldTouched(`form.questions.${index}.value`, true);
          }}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Sim" />
          <FormControlLabel value="no" control={<Radio />} label="Não" />
        </RadioGroup>
      </>
    );
  } else if (question.type === "number") {
    return (
      <TextField
        type="number"
        label={question.name}
        size="small"
        required={question.option === "required"}
        onChange={(e) => {
          formik.setFieldValue(`form.questions.${index}.value`, e.target.value);
        }}
        onBlur={formik.handleBlur(`form.questions.${index}.value`)}
        value={getIn(formik.values, `form.questions.${index}.value`)}
        error={
          !!(
            getIn(formik.touched, `form.questions.${index}.value`) &&
            getIn(formik.errors, `form.questions.${index}.value`)
          )
        }
        helperText={
          getIn(formik.touched, `form.questions.${index}.value`) &&
          getIn(formik.errors, `form.questions.${index}.value`)
            ? getIn(formik.errors, `form.questions.${index}.value`)
            : null
        }
      />
    );
  } else if (question.type === "date") {
    return (
      <DatePicker
        label={question.name}
        onChange={(newValue: any) => {
          formik.setFieldValue(`form.questions.${index}.value`, newValue);
        }}
        value={getIn(formik.values, `form.questions.${index}.value`)}
        slotProps={{
          textField: {
            required: question.option === "required",
            size: "small",
            error: !!(
              getIn(formik.touched, `form.questions.${index}.value`) &&
              getIn(formik.errors, `form.questions.${index}.value`)
            ),
            helperText:
              getIn(formik.touched, `form.questions.${index}.value`) &&
              getIn(formik.errors, `form.questions.${index}.value`)
                ? getIn(formik.errors, `form.questions.${index}.value`)
                : null,
            sx: {
              "& .MuiInputBase-root": {
                backgroundColor: (theme) => theme.palette.common.white,
              },
            },
          },
        }}
      />
    );
  }
  return null;
};

export default QuestionInput;
