import { AxiosResponse } from "axios";
import { ResponseWithErrors } from "../../interfaces";
import requestHelper from "../../utils/requestHelper";
import {
  Form,
  JobStatus,
  State,
  WorkingModel,
  WorkingType,
} from "../../utils/types";
import { DepartmentIndexAttributes } from "../department/departmentApi";
import { BenefitIndexAttributes } from "../benefit/benefitApi";

export interface JobIndexAttributes {
  id: number;
  name: string;
  status: JobStatus;
  working_type: WorkingType;
  working_model: WorkingModel;
}

export interface JobIndexResponse extends ResponseWithErrors {
  records: JobIndexAttributes[];
}

export interface JobParams {
  id: number | null;
  name: string | null;
  status: JobStatus;
  working_type: WorkingType;
  working_type_visible: boolean;
  working_model: WorkingModel;
  state: State;
  city: string | null;
  description: string | null;
  step: number | null;
  internal_id: string | null;
  is_pwd: boolean;
  minimum_salary_range: string | null;
  maximum_salary_range: string | null;
  salary_range_visible: boolean;
  department_visible: boolean;
  department: DepartmentIndexAttributes | null;
  department_id: number | null;
  requirements: string[];
  benefits: BenefitIndexAttributes[];
  benefit_ids: number[];
  users: {
    id: number;
    name: string;
    disabled: boolean;
  }[];
  user_ids: number[];
  form: Form;
  job_pipelines_attributes: {
    id: number | null;
    name: string;
    order: number;
    _destroy?: boolean;
    static: boolean;
  }[];
}

export interface JobShowResponse {
  id: number;
  name: string | null;
  status: JobStatus;
  working_type: WorkingType;
  working_type_visible: boolean;
  working_model: WorkingModel;
  state: State;
  city: string | null;
  description: string | null;
  published_at: Date | null;
  step: number | null;
  internal_id: string | null;
  is_pwd: boolean;
  minimum_salary_range: string | null;
  maximum_salary_range: string | null;
  salary_range_visible: boolean;
  department_visible: boolean;
  department: DepartmentIndexAttributes | null;
  requirements: string[];
  benefits: BenefitIndexAttributes[];
  users: {
    id: number;
    name: string;
    disabled: boolean;
  }[];
  company: {
    name: string | null;
    slogan: string | null;
    logo: string | null;
    banner: string | null;
  };
  form: Form;
  owner: {
    id: number;
    name: string;
  };
  job_pipelines: {
    id: number;
    name: string;
    order: number;
    static: boolean;
  }[];
}

interface JobNewResponse {
  users: {
    id: number;
    name: string;
    disabled: boolean;
  }[];
}
interface JobPipelinesResponse extends ResponseWithErrors {
  records: JobPipelinesAttributes[];
}

export interface JobPipelinesAttributes {
  id: number;
  name: string;
  order: number;
  candidates: {
    id: number;
    name: string;
    email: string;
    rating: number;
    rank_order: number;
    linkedin: string | null;
    facebook: string | null;
    instagram: string | null;
    tiktok: string | null;
    github: string | null;
    resume: { name: string; url: string } | null;
  }[];
}

export interface JobResponse extends JobShowResponse, ResponseWithErrors {}

const index = async (
  company_id: number
): Promise<AxiosResponse<JobIndexResponse>> => {
  return await requestHelper
    .get(`/jobs/${company_id}/list`)
    .catch((e) => e.response);
};

const newJob = async (): Promise<AxiosResponse<JobNewResponse>> => {
  return await requestHelper.get(`/jobs`).catch((e) => e.response);
};

const create = async (
  params: JobParams
): Promise<AxiosResponse<JobResponse>> => {
  return await requestHelper
    .post(`jobs`, {
      job: params,
    })
    .catch((e) => e.response);
};

const show = async (id: string): Promise<AxiosResponse<JobShowResponse>> => {
  return await requestHelper.get(`jobs/${id}`).catch((e) => e.response);
};

const update = async (
  params: JobParams
): Promise<AxiosResponse<JobResponse>> => {
  return await requestHelper
    .patch(`jobs/${params.id}`, {
      job: params,
    })
    .catch((e) => e.response);
};

const destroy = async (id: string): Promise<AxiosResponse<JobShowResponse>> => {
  return await requestHelper.delete(`jobs/${id}`).catch((e) => e.response);
};

const publish = async (id: string): Promise<AxiosResponse<JobResponse>> => {
  return await requestHelper
    .patch(`jobs/${id}/publish`)
    .catch((e) => e.response);
};

const unpublish = async (id: string): Promise<AxiosResponse<JobResponse>> => {
  return await requestHelper
    .patch(`jobs/${id}/unpublish`)
    .catch((e) => e.response);
};

const pipelines = async (
  id: string
): Promise<AxiosResponse<JobPipelinesResponse>> => {
  return await requestHelper
    .get(`jobs/${id}/pipelines`)
    .catch((e) => e.response);
};

export {
  index,
  newJob,
  create,
  show,
  update,
  destroy,
  publish,
  unpublish,
  pipelines,
};
