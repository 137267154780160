import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  HTMLAttributes,
} from "react";
import { createPortal } from "react-dom";
import { styled } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

interface IIFrame extends HTMLAttributes<HTMLIFrameElement> {
  children: React.ReactNode;
}

const PreviewIFrame = styled("iframe")(() => ({
  border: "none",
  overflow: "hidden",
  height: "80vh",
  width: "100%",
}));

const IFrame: React.FC<IIFrame> = ({ children, ...props }) => {
  const contentRef = useRef<HTMLIFrameElement>(null);
  const [doc, setDoc] = useState(contentRef.current?.contentWindow?.document);

  const mountNode = useMemo(() => {
    return doc?.body;
  }, [doc]);

  const head = useMemo(() => {
    return doc?.head;
  }, [doc]);

  useEffect(() => {
    setDoc(contentRef.current?.contentWindow?.document);
  }, [contentRef.current]);

  useEffect(() => {
    if (head && doc) {
      const cssLink = doc.createElement("link");
      cssLink.setAttribute("rel", "stylesheet");
      cssLink.setAttribute("type", "text/css");
      cssLink.setAttribute("href", "/index.css");
      head.appendChild(cssLink);

      const fontAwesomeLink = doc.createElement("link");
      fontAwesomeLink.setAttribute("rel", "stylesheet");
      fontAwesomeLink.setAttribute("type", "text/css");
      fontAwesomeLink.setAttribute(
        "href",
        "https://use.fontawesome.com/releases/v6.5.1/css/all.css"
      );
      head.appendChild(fontAwesomeLink);
    }
  }, [head, doc]);

  const cache = createCache({
    key: "css",
    container: head,
    prepend: true,
  });

  return (
    <PreviewIFrame {...props} ref={contentRef}>
      {mountNode &&
        createPortal(
          <CacheProvider value={cache}>{children}</CacheProvider>,
          mountNode
        )}
    </PreviewIFrame>
  );
};
export default IFrame;
