import React from "react";
import { Box, Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import urls from "../../utils/urls";

interface IBanner {
  src?: string;
  banner?: string;
  slug?: string;
}

const Logo: React.FC<IBanner> = ({ src, banner, slug }) => {
  return src ? (
    <Box
      sx={{
        width: "fit-content",
        ml: "auto",
        mr: "auto",
        mt: banner ? -3 : 3,
      }}
    >
      <Link to={slug ? urls.companyPage.replace(":slug", slug) : ""}>
        <Avatar
          src={src}
          alt="logo"
          sx={{
            width: 100,
            height: 100,
            border: "5px solid #fff",
            boxShadow: "0px 0px 11px 1px rgba(0,0,0,0.59)",
            backgroundColor: (theme) => theme.palette.common.white,
          }}
        />
      </Link>
    </Box>
  ) : null;
};

export default Logo;
