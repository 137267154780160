import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  colors,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Rating,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../app/store";
import { jobSelector } from "../redux/job/jobSlice";
import { Link, useParams } from "react-router-dom";
import { getPipelines, showJob } from "../redux/job/jobActions";
import Backdrop from "../components/Backdrop";
import usePermission from "../hooks/usePermission";
import { LoadingButton } from "@mui/lab";
import { texts } from "../utils/texts";
import urls from "../utils/urls";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {
  Download,
  Email,
  Facebook,
  GitHub,
  Instagram,
  LinkedIn,
  MoreVert,
  Share,
} from "@mui/icons-material";
import MenuDropdown, {
  IMenuDropdownRef,
  MenuItemDropdown,
} from "../components/MenuDropdown";
import tiktok_logo from "./../assets/images/tiktok_logo.png";
import { candidateSelector } from "../redux/candidate/candidateSlice";
import { updateCandidate } from "../redux/candidate/candidateActions";
import { getIn, useFormik } from "formik";
import { toast } from "react-toastify";

const LINKEDIN_BASE_URL = "https://www.linkedin.com/in/";
const FACEBOOK_BASE_URL = "https://www.facebook.com/";
const INSTAGRAM_BASE_URL = "https://www.instagram.com/";
const TIKTOK_BASE_URL = "https://www.tiktok.com/@";
const GITHUB_BASE_URL = "https://www.github.com/";

const CandidateJobs = () => {
  const dispatch = useAppDispatch();
  const { width, height } = useWindowDimensions();
  const containerRef = useRef<HTMLDivElement>(null);
  const cardContainerRef = useRef<HTMLDivElement>(null);
  const moreRefs = useRef<{ [key: number]: IMenuDropdownRef | null }>({});
  const { id } = useParams();
  const theme = useTheme();

  const hasPermissionToJobsUpdate = usePermission("jobs.update");

  const jobState = useAppSelector(jobSelector);
  const candidateState = useAppSelector(candidateSelector);

  const [containerOffsetTop, setContainerOffsetTop] = useState<number>(0);
  const [cardContainerOffsetTop, setCardContainerOffsetTop] =
    useState<number>(0);

  useEffect(() => {
    if (containerRef.current) {
      setContainerOffsetTop(containerRef.current.offsetTop);
    }
  }, [containerRef.current, width, height]);

  useEffect(() => {
    if (cardContainerRef.current) {
      setCardContainerOffsetTop(cardContainerRef.current.offsetTop);
    }
  }, [cardContainerRef.current, width, height]);

  useEffect(() => {
    if (id) {
      dispatch(showJob(id.toString()));
      dispatch(getPipelines(id.toString()));
    }
  }, [id]);

  const formik = useFormik({
    initialValues: {
      pipelines: jobState.pipelines || []
    },
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const updateRating = (pipelineIndex: number, candidateIndex: number, candidateId: number, rating: number) => {
    const field = `pipelines.${pipelineIndex}.candidates.${candidateIndex}.rating`
    const oldRating = getIn(formik.values, field)
    formik.setFieldValue(field, rating)

    dispatch(updateCandidate({ params: { id: candidateId, rating } })).then((value) => {
      if (value.meta.requestStatus === "rejected") {
        formik.setFieldValue(field, oldRating)
        toast.error("Não foi possível avaliar o candidato, tente novamente!")
      }
    });
  };

  return (
    <Box>
      {jobState.isFinding || jobState.isFetchingPipelines ? (
        <Backdrop />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 4,
              width: "100%",
            }}
          >
            <Typography variant="h5">{jobState.job?.name}</Typography>
            <Tooltip
              title={!hasPermissionToJobsUpdate ? texts.notAuthorized : ""}
            >
              <Box>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  size="large"
                  disabled={!hasPermissionToJobsUpdate}
                >
                  <Link
                    to={urls.manageJob.replace(":id", id?.toString() || "")}
                  >
                    Atualizar vaga
                  </Link>
                </LoadingButton>
              </Box>
            </Tooltip>
          </Box>

          <Box
            ref={containerRef}
            sx={{
              display: "flex",
              gap: 2,
              mt: 4,
              overflowX: "auto",
              overflowY: "hidden",
              height: `calc(${height - containerOffsetTop - 32}px)`,
              pb: 1,
            }}
          >
            {formik.values.pipelines.map((pipeline, pipelineIndex) => (
              <Box
                key={pipeline.id}
                sx={{
                  backgroundColor: colors.grey[100],
                  borderRadius: 2,
                  width: "280px",
                  flexShrink: 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    px: 2,
                    py: 1,
                  }}
                >
                  <Typography
                    fontWeight="bold"
                    fontSize={20}
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {pipeline.name}
                  </Typography>
                  <Box
                    sx={{
                      ml: 1,
                      backgroundColor: (theme) => theme.palette.primary.main,
                      color: (theme) => theme.palette.common.white,
                      height: "20px",
                      minWidth: "20px",
                      borderRadius: "10px",
                      padding: "0 6px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxSizing: "border-box",
                      lineHeight: 1,
                      flexShrink: 0,
                    }}
                  >
                    <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                      {pipeline.candidates.length}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                <Box
                  ref={cardContainerRef}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    p: 1,
                    overflowY: "auto",
                    height: `calc(${height - cardContainerOffsetTop - 63}px)`,
                  }}
                >
                  {pipeline.candidates.map((candidate, candidateIndex) => (
                    <Card key={candidate.id} sx={{ width: "100%", overflow: "initial" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                          pt: 2,
                          pl: 2,
                          pr: 0.5,
                        }}
                      >
                        <Tooltip title={candidate.name} placement="top">
                          <Typography
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {candidate.name}
                          </Typography>
                        </Tooltip>

                        <IconButton
                          size="small"
                          onClick={(e) => moreRefs?.current[candidate.id]?.open(e)}
                        >
                          <MoreVert />
                        </IconButton>

                        <MenuDropdown id={`more-candidate-data-${candidate.id}`} ref={(el) => moreRefs.current[candidate.id] = el}>
                          {candidate.resume ? (
                            <MenuItemDropdown
                              component="a"
                              href={candidate.resume.url}
                              download
                              target="_blank"
                            >
                              <ListItemIcon>
                                <Download fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Baixar Currículo</ListItemText>
                            </MenuItemDropdown>
                          ) : null}
                          <MenuItemDropdown
                            component="a"
                            href={`mailto:${candidate.email}`}
                            target="_blank"
                          >
                            <ListItemIcon>
                              <Email fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Enviar E-mail</ListItemText>
                          </MenuItemDropdown>
                          <MenuItemDropdown>
                            <ListItemIcon>
                              <Share fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Compartilhar Perfil</ListItemText>
                          </MenuItemDropdown>
                        </MenuDropdown>
                      </Box>

                      <Box sx={{ px: 2, pb: 2 }}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          {candidate?.linkedin && (
                            <Link
                              to={`${LINKEDIN_BASE_URL}${candidate.linkedin}`}
                              target="_blank"
                            >
                              <LinkedIn
                                sx={{
                                  cursor: "pointer",
                                  color: theme.palette.common.black,
                                }}
                                fontSize="small"
                              />
                            </Link>
                          )}
                          {candidate?.facebook && (
                            <Link
                              to={`${FACEBOOK_BASE_URL}${candidate.facebook}`}
                              target="_blank"
                            >
                              <Facebook
                                sx={{
                                  cursor: "pointer",
                                  color: theme.palette.common.black,
                                }}
                                fontSize="small"
                              />
                            </Link>
                          )}
                          {candidate?.instagram && (
                            <Link
                              to={`${INSTAGRAM_BASE_URL}${candidate.instagram}`}
                              target="_blank"
                            >
                              <Instagram
                                sx={{
                                  cursor: "pointer",
                                  color: theme.palette.common.black,
                                }}
                                fontSize="small"
                              />
                            </Link>
                          )}
                          {candidate?.tiktok && (
                            <Link
                              to={`${TIKTOK_BASE_URL}${candidate.tiktok}`}
                              target="_blank"
                            >
                              <img
                                src={tiktok_logo}
                                width="18px"
                                height="18px"
                                style={{
                                  marginTop: "1px",
                                  cursor: "pointer",
                                  color: theme.palette.common.black,
                                }}
                              />
                            </Link>
                          )}
                          {candidate?.github && (
                            <Link
                              to={`${GITHUB_BASE_URL}${candidate.github}`}
                              target="_blank"
                            >
                              <GitHub
                                sx={{
                                  cursor: "pointer",
                                  color: theme.palette.common.black,
                                }}
                                fontSize="small"
                              />
                            </Link>
                          )}
                        </Box>

                        <Box sx={{ mt: 1 }}>
                          <Rating
                            value={candidate.rating}
                            onChange={(e, newValue) =>
                              updateRating(pipelineIndex, candidateIndex, candidate.id, newValue || 0)
                            }
                          />
                        </Box>
                      </Box>
                    </Card>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default CandidateJobs;
