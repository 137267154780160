import React from "react";
import { Avatar as AvatarCore, colors } from "@mui/material";

interface IAvatar {
  src: string | null;
  name: string;
  nameInitials?: string;
  disableBorder?: boolean;
}

const Avatar: React.FC<IAvatar> = ({
  src,
  name,
  nameInitials,
  disableBorder = false,
}) => {
  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const companyInitials = (name: string | undefined) => {
    const nameSplitted = name?.split(" ");
    if (nameSplitted && nameSplitted.length >= 2) {
      return `${nameSplitted[0][0]}${nameSplitted[1][0]}`;
    }

    return nameSplitted ? nameSplitted[0][0] : "";
  };

  return (
    <AvatarCore
      src={src || undefined}
      sx={{
        width: 28,
        height: 28,
        fontSize: 14,
        bgcolor: !src && name ? stringToColor(name) : colors.common.white,
        border: src && !disableBorder ? 1 : 0,
        borderColor: colors.grey[300],
      }}
    >
      {!src && companyInitials(nameInitials || name)}
    </AvatarCore>
  );
};

export default Avatar;
