import { createSlice } from "@reduxjs/toolkit";
import { RequestNewPasswordResponse } from "./passwordApi";
import { requestNewPassword, resetPassword } from "./passwordAction";
import filterErrorMessage from "../../utils/filterErrorMessage";
import { RootState } from "../../app/store";

interface initialState {
  isSaving: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string | null;
}

export const passwordSlice = createSlice({
  name: "password",
  initialState: {
    isSaving: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
  } as initialState,

  reducers: {
    clearState: (state) => {
      state.isSaving = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(requestNewPassword.pending, (state) => {
      state.isSaving = true;
      state.isSuccess = false;
    });
    builder.addCase(requestNewPassword.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSuccess = true;
    });
    builder.addCase(requestNewPassword.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as RequestNewPasswordResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(resetPassword.pending, (state) => {
      state.isSaving = true;
      state.isSuccess = false;
    });
    builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSuccess = true;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as RequestNewPasswordResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });
  },
});

export const { clearState } = passwordSlice.actions;

export const passwordSelector = (state: RootState) => state.password;
