import { createSlice } from "@reduxjs/toolkit";
import { showCompany, updateCompany } from "./companyActions";
import { RootState } from "../../app/store";
import {
  CompanyResponse,
  CompanyShowResponse,
  CompanyParams,
} from "./companyApi";
import filterErrorMessage from "../../utils/filterErrorMessage";

export interface initialState {
  initialValues: CompanyParams;
  company: CompanyResponse | CompanyShowResponse | null;
  isSaving: boolean;
  isFinding: boolean;
  isSaveSuccess: boolean;
  isFindingSuccess: boolean;
  isError: boolean;
  errorMessage: string | null;
}

const initialCompanyValues = {
  id: null,
  slug: "",
  website: "",
  linkedin: "",
  facebook: "",
  instagram: "",
  twitter: "",
  glassdoor: "",
  primary_color: "#000000",
  name: "",
  slogan: "",
  description_title: "",
  description: "",
  logo: null,
  banner: null,
};

export const companySlice = createSlice({
  name: "company",
  initialState: {
    initialValues: initialCompanyValues,
    company: null,
    isSaving: false,
    isFinding: false,
    isSaveSuccess: false,
    isFindingSuccess: false,
    isError: false,
    errorMessage: null,
  } as initialState,

  reducers: {
    clearState: (state) => {
      state.isSaving = false;
      state.isFinding = false;
      state.isSaveSuccess = false;
      state.isFindingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
    clearShowState: (state) => {
      state.company = null;
      state.isFindingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(showCompany.pending, (state) => {
      state.isFinding = true;
      state.isFindingSuccess = false;
    });
    builder.addCase(showCompany.fulfilled, (state, { payload }) => {
      state.isFinding = false;
      state.isFindingSuccess = true;
      state.company = payload;
    });
    builder.addCase(showCompany.rejected, (state, action) => {
      state.isFinding = false;
      state.isError = true;
      const payload = action.payload as CompanyResponse;
      state.errorMessage = filterErrorMessage(
        payload?.error || payload?.error_messages?.[0]
      );
    });

    builder.addCase(updateCompany.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(updateCompany.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.company = payload;
    });
    builder.addCase(updateCompany.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as CompanyResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });
  },
});

export const { clearState, clearShowState } = companySlice.actions;

export const companySelector = (state: RootState) => state.company;
