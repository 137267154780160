import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/store";
import {
  clearDestroyState,
  clearShowState,
  clearState,
  profileSelector,
  initialProfileValues,
} from "../redux/profile/profileSlice";
import {
  createProfile,
  showProfile,
  updateProfile,
} from "../redux/profile/profileActions";
import { toast } from "react-toastify";
import urls from "../utils/urls";
import { useFormik } from "formik";
import {
  Box,
  Typography,
  Paper,
  Grid,
  FormGroup,
  FormControl,
  TextField,
  Tooltip,
} from "@mui/material";
import Backdrop from "../components/Backdrop";
import LoadingButton from "@mui/lab/LoadingButton";
import PermissionTreeView from "../components/PermissionTreeView";
import usePermission from "../hooks/usePermission";
import { texts } from "../utils/texts";

function ManageProfiles() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const hasPermissionToProfilesCreate = usePermission("profiles.create");
  const hasPermissionToProfilesUpdate = usePermission("profiles.update");

  const profileState = useAppSelector(profileSelector);

  useEffect(() => {
    if (id) {
      dispatch(showProfile(id));
    }
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(clearState());
      dispatch(clearShowState());
      dispatch(clearDestroyState());
    };
  }, []);

  useEffect(() => {
    if (profileState.isSaveSuccess) {
      toast.success("Perfil salvo com sucesso");
      navigate(urls.profiles);
    }
  }, [profileState.isSaveSuccess]);

  const isEditable = !!(!id || profileState.profile?.editable);

  const formik = useFormik({
    initialValues: profileState.profile
      ? {
          id: profileState.profile.id,
          name: profileState.profile.name,
          permission_ids: profileState.profile.permission_ids,
        }
      : initialProfileValues,
    onSubmit: (values, formikBag) => {
      if (values.id) {
        dispatch(
          updateProfile({
            params: values,
            formikBag: formikBag,
          })
        );
      } else {
        dispatch(
          createProfile({
            params: values,
            formikBag: formikBag,
          })
        );
      }
    },
    enableReinitialize: true,
  });

  const noHasPermissionToCreateOrUpdate =
    (id && !hasPermissionToProfilesUpdate) ||
    (!id && !hasPermissionToProfilesCreate);

  return (
    <Box>
      {profileState.isFinding && <Backdrop />}
      <Typography variant="h5">
        {id ? "Atualizar perfil" : "Cadastrar novo perfil"}
      </Typography>
      <Paper sx={{ mb: 2, mt: 2, p: { xs: 1, sm: 2 } }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  label="Nome"
                  size="small"
                  required
                  onChange={formik.handleChange("name")}
                  onBlur={formik.handleBlur("name")}
                  value={formik.values.name}
                  error={!!(formik.touched.name && formik.errors.name)}
                  helperText={
                    formik.touched.name && formik.errors.name
                      ? formik.errors.name
                      : null
                  }
                  disabled={!isEditable}
                />
              </FormControl>
            </FormGroup>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4 }}>
          <PermissionTreeView formik={formik} isEditable={isEditable} />
        </Box>

        {isEditable && (
          <Tooltip
            title={noHasPermissionToCreateOrUpdate ? texts.notAuthorized : ""}
          >
            <Box sx={{ width: "fit-content" }}>
              <LoadingButton
                variant="contained"
                type="submit"
                size="large"
                onClick={() => formik.handleSubmit()}
                loading={profileState.isSaving}
                disabled={noHasPermissionToCreateOrUpdate}
                sx={{ mt: 4 }}
              >
                {id ? "Atualizar" : "Cadastrar"}
              </LoadingButton>
            </Box>
          </Tooltip>
        )}
      </Paper>
    </Box>
  );
}

export default ManageProfiles;
