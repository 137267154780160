import { createAsyncThunk } from "@reduxjs/toolkit";
import { FormikHelpers } from "formik";
import * as companyApi from "./companyApi";
import statusCodes from "../../utils/statusCodes";
import setFormikErrors from "../../utils/setFormikErrors";

export const showCompany = createAsyncThunk(
  "company/show",
  async (id: string, thunkAPI) => {
    try {
      const response = await companyApi.show(id);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateCompany = createAsyncThunk(
  "company/update",
  async (
    {
      formData,
      formikBag,
    }: {
      formData: FormData;
      formikBag: FormikHelpers<companyApi.CompanyParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await companyApi.update(formData);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
