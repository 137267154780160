import { AxiosResponse } from "axios";
import { ResponseWithErrors } from "../../interfaces";
import requestHelper from "../../utils/requestHelper";

export interface ProfileIndexAttributes {
  id: number;
  name: string;
  editable: boolean;
  permission_ids: Array<number>;
}

export interface ProfileIndexResponse extends ResponseWithErrors {
  records: ProfileIndexAttributes[];
}

export interface ProfileParams {
  id: number | null;
  name: string | null;
  permission_ids: Array<number>;
}

export interface ProfileShowResponse {
  id: number;
  name: string;
  editable: boolean;
  permission_ids: Array<number>;
}

export interface ProfileResponse
  extends ProfileShowResponse,
    ResponseWithErrors {}

const index = async (): Promise<AxiosResponse<ProfileIndexResponse>> => {
  return await requestHelper.get(`/profiles`).catch((e) => e.response);
};

const create = async (
  params: ProfileParams
): Promise<AxiosResponse<ProfileResponse>> => {
  return await requestHelper
    .post(`profiles`, {
      profile: params,
    })
    .catch((e) => e.response);
};

const show = async (
  id: string
): Promise<AxiosResponse<ProfileShowResponse>> => {
  return await requestHelper.get(`profiles/${id}`).catch((e) => e.response);
};

const update = async (
  params: ProfileParams
): Promise<AxiosResponse<ProfileResponse>> => {
  return await requestHelper
    .patch(`profiles/${params.id}`, {
      profile: params,
    })
    .catch((e) => e.response);
};

const destroy = async (
  id: string
): Promise<AxiosResponse<ProfileShowResponse>> => {
  return await requestHelper.delete(`profiles/${id}`).catch((e) => e.response);
};

export { index, create, show, update, destroy };
