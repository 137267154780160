import React, { ChangeEvent, useState } from "react";
import {
  Avatar,
  Box,
  ButtonBase,
  FormHelperText,
  Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import VisuallyHiddenInput from "./Input/VisuallyHiddenInput";
import CropperModal from "./CropperModal";

interface IAvatarPicker {
  formik: FormikProps<any>;
  field: string;
  label: string;
  helperText?: string | null;
}

const AvatarPicker: React.FC<IAvatarPicker> = ({
  formik,
  field,
  label,
  helperText,
}) => {
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);
  const [cropperOpen, setCropperOpen] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    if (file) {
      formik.setFieldValue(field, file);

      const reader = new FileReader();

      reader.onload = function (e) {
        if (e.target?.result) {
          setPreview(e.target.result);
          setCropperOpen(true);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropped = (file: File) => {
    formik.setFieldValue(field, file);

    const reader = new FileReader();

    reader.onload = function (e) {
      if (e.target?.result) {
        setPreview(e.target.result);
      }
      setCropperOpen(false);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography fontWeight="bold">{label}</Typography>
      <ButtonBase
        disableTouchRipple
        disableRipple
        component="label"
        sx={{ borderRadius: "100%", justifyContent: "flex-start" }}
      >
        <Avatar
          src={preview || eval(`formik.values.${field}`) || undefined}
          sx={{ width: 100, height: 100, cursor: "pointer" }}
        />
        <VisuallyHiddenInput
          type="file"
          accept=".jpeg, .jpg, .png"
          onChange={handleChange}
        />
      </ButtonBase>
      <CropperModal
        title={`Ajustar ${label}`}
        image={preview?.toString()}
        open={cropperOpen}
        onClose={() => setCropperOpen(false)}
        onCrop={handleCropped}
        cropperProps={{ cropShape: "round", restrictPosition: false }}
      />

      {helperText ? (
        <FormHelperText sx={{ mt: 1 }}>{helperText}</FormHelperText>
      ) : null}
    </Box>
  );
};

export default AvatarPicker;
