import { useMemo, useState } from "react";
import { Address, getCepInfo, index as getCities } from "../redux/city/cityApi";
import { FormikProps } from "formik";
import statusCodes from "../utils/statusCodes";
import { stateOptions } from "../utils/constants";
import { State } from "../utils/types";

export default function useAddress(
  formik: FormikProps<any>,
  initialStateValue?: State | null,
  field_prefix: string = ""
) {
  const [cities, setCities] = useState<string[]>([]);
  const [loadingCities, setLoadingCities] = useState<boolean>(false);

  const onChangeState = async (
    option: {
      id: string;
      label: string;
    } | null
  ) => {
    const newState = option?.id || "";
    formik.setFieldValue(`${field_prefix}city`, "");
    formik.setFieldValue(`${field_prefix}state`, newState);
    if (newState !== "") {
      setCitiesField(newState);
    }
  };

  const setCitiesField = async (state?: string) => {
    const _state = state || initialStateValue;
    if (_state) {
      setLoadingCities(true);
      const response = await getCities(_state);
      if (response.status === statusCodes.ok) {
        setCities(response.data);
      }
      setLoadingCities(false);
    }
  };

  const getAddressFromCep = async (cep: string): Promise<Address | null> => {
    if (cep) {
      const response = await getCepInfo(cep);
      if (response.status === statusCodes.ok) {
        return response.data;
      }
    }
    return null;
  };

  const stateValue = useMemo(() => {
    return stateOptions.find(
      (stateOption) =>
        stateOption.id === eval(`formik.values.${field_prefix}state`)
    );
  }, [eval(`formik.values.${field_prefix}state`)]);

  const cityIsDisabled = useMemo(() => {
    return eval(`formik.values.${field_prefix}state`) === "";
  }, [eval(`formik.values.${field_prefix}state`)]);

  return {
    cities,
    loadingCities,
    stateValue,
    cityIsDisabled,
    onChangeState,
    setCitiesField,
    getAddressFromCep,
  };
}
