import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/store";
import { clearState, userSelector } from "../redux/user/userSlice";
import { fetchUserByToken } from "../redux/user/userActions";
import { clearLocalStorage, getToken } from "../utils/localStorage";
import Backdrop from "./Backdrop";
import urls from "../utils/urls";

const CandidateRoute = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isUnauthorized, isFinding, currentUser } =
    useAppSelector(userSelector);

  const token = getToken();

  useEffect(() => {
    dispatch(fetchUserByToken());

    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (isUnauthorized || (currentUser.id && !currentUser.is_candidate)) {
      clearLocalStorage();
      navigate(`${urls.candidateLogin}?return_url=${window.location.pathname}`);
    }
  }, [isUnauthorized, currentUser]);

  if (!token || (!token && isFinding)) {
    return <Backdrop />;
  }

  return <>{children}</>;
};

export default CandidateRoute;
