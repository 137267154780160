import { AxiosResponse } from "axios";
import requestHelper from "../../utils/requestHelper";

export interface PermissionIndexAttributes {
  id: number;
  name: string;
  children: PermissionIndexAttributes[];
}

export interface PermissionIndexResponse {
  records: PermissionIndexAttributes[];
}

const index = async (): Promise<AxiosResponse<PermissionIndexResponse>> => {
  return await requestHelper.get(`permissions`).catch((e) => e.response);
};

export { index };
