import React, { useEffect } from "react";
import LoginCardContainer from "../components/LoginCardContainer";
import {
  Alert,
  Box,
  FormControl,
  FormGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { colors } from "../assets/themes";
import { LoadingButton } from "@mui/lab";
import Yup from "../utils/yup";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../app/store";
import { requestNewPassword } from "../redux/password/passwordAction";
import { clearState, passwordSelector } from "../redux/password/passwordSlice";
import { Link } from "react-router-dom";
import urls from "../utils/urls";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const passwordState = useAppSelector(passwordSelector);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, formikBag) => {
      dispatch(requestNewPassword({ params: values, formikBag }));
    },
    enableReinitialize: true,
  });

  return (
    <LoginCardContainer>
      <Typography color={colors.grey} fontSize={15} fontWeight="400">
        Digite seu e-mail, em seguida você receberá um e-mail com o link para
        redefinição de sua senha
      </Typography>

      {passwordState.isSuccess ? (
        <Alert severity="success" aria-live="assertive" sx={{ mb: 4, mt: 2 }}>
          E-mail enviado com sucesso, confira sua caixa de mensagens!
        </Alert>
      ) : (
        <></>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <FormGroup sx={{ marginTop: 2 }}>
          <FormControl fullWidth>
            <TextField
              id="email"
              type="email"
              label="E-mail"
              onChange={formik.handleChange("email")}
              onBlur={formik.handleBlur("email")}
              value={formik.values.email}
              error={!!(formik.touched.email && formik.errors.email)}
              helperText={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
          </FormControl>
        </FormGroup>

        <FormGroup sx={{ marginTop: 4 }}>
          <FormControl fullWidth>
            <LoadingButton
              variant="contained"
              loading={passwordState.isSaving}
              type="submit"
              size="large"
            >
              Recuperar Senha
            </LoadingButton>
          </FormControl>
        </FormGroup>
      </form>

      <Box sx={{ marginTop: 4, textAlign: "center" }}>
        <Typography fontSize={14} color={colors.grey}>
          <Link
            to={urls.login}
            style={{
              textDecoration: "none",
              color: theme.palette.primary.main,
            }}
          >
            Voltar ao login
          </Link>
        </Typography>
      </Box>
    </LoginCardContainer>
  );
};

export default ForgotPassword;
