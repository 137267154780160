import { createAsyncThunk } from "@reduxjs/toolkit";
import { FormikHelpers } from "formik";
import * as departmentApi from "./departmentApi";
import statusCodes from "../../utils/statusCodes";
import setFormikErrors from "../../utils/setFormikErrors";

export const getDepartments = createAsyncThunk(
  "departments/index",
  async (_params, thunkAPI) => {
    try {
      const response = await departmentApi.index();
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createDepartment = createAsyncThunk(
  "departments/create",
  async (
    {
      params,
      formikBag,
    }: {
      params: departmentApi.DepartmentParams;
      formikBag?: FormikHelpers<departmentApi.DepartmentParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await departmentApi.create(params);
      const { data } = response;
      if (response.status === statusCodes.created) {
        return data;
      } else {
        if (formikBag) {
          setFormikErrors(data?.errors, formikBag);
        }
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const showDepartment = createAsyncThunk(
  "departments/show",
  async (id: string, thunkAPI) => {
    try {
      const response = await departmentApi.show(id);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateDepartment = createAsyncThunk(
  "departments/update",
  async (
    {
      params,
      formikBag,
    }: {
      params: departmentApi.DepartmentParams;
      formikBag: FormikHelpers<departmentApi.DepartmentParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await departmentApi.update(params);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const destroyDepartment = createAsyncThunk(
  "departments/destroy",
  async (id: string, thunkAPI) => {
    try {
      const response = await departmentApi.destroy(id);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
