import { createAsyncThunk } from "@reduxjs/toolkit";
import { FormikHelpers } from "formik";
import * as passwordApi from "../password/passwordApi";
import statusCodes from "../../utils/statusCodes";
import setFormikErrors from "../../utils/setFormikErrors";

export const requestNewPassword = createAsyncThunk(
  "passwords/requestNewPassword",
  async (
    {
      params,
      formikBag,
    }: {
      params: passwordApi.RequestNewPasswordParams;
      formikBag: FormikHelpers<passwordApi.RequestNewPasswordParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await passwordApi.requestNewPassword(params);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "passwords/resetPassword",
  async (
    {
      token,
      params,
      formikBag,
    }: {
      token: string;
      params: passwordApi.ResetPasswordParams;
      formikBag: FormikHelpers<passwordApi.ResetPasswordParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await passwordApi.resetPassword(token, params);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
