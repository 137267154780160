import {
  InputLabel,
  Select as SelectCore,
  InputLabelOwnProps,
  FormHelperText,
  SelectProps,
} from "@mui/material";
import React from "react";

type ISelect = SelectProps & {
  labelId?: string;
  helperText?: string | null;
}

const Select: React.FC<ISelect> = ({ labelId, children, ...props }) => {
  return (
    <>
      {props.label && (
        <InputLabel
          id={labelId}
          size={props.size as InputLabelOwnProps["size"]}
        >
          {props.label}
        </InputLabel>
      )}
      <SelectCore
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          ...props.sx,
        }}
        {...props}
      >
        {children}
      </SelectCore>
      {props.helperText ? (
        <FormHelperText>{props.helperText}</FormHelperText>
      ) : null}
    </>
  );
};

export default Select;
