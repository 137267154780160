export const maskCurrency = (
  value: any,
  options?: Intl.NumberFormatOptions
): string => {
  value = value.replace(/\D/g, "");
  value = new Intl.NumberFormat("pt-BR", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    ...options,
  }).format(value / 100);
  return value;
};

export const maskInteger = (value: any) => {
  return value.replace(/[^0-9]/g, "");
};

export const maskPhoneNumber = (value: any): string => {
  if (!value) {
    return value;
  }

  value = value.replace(/\D/g, "");
  value = value.replace(/^0/, "");
  if (value.length > 10) {
    value = value.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (value.length > 5) {
    value = value.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  } else if (value.length > 2) {
    value = value.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else {
    value = value.replace(/^(\d*)/, "($1");
  }

  return value;
};

export const maskCep = (value: any): string => {
  if (!value) {
    return value;
  }

  return value
    .replace(/\D/g, "")
    .replace(/^(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1");
};
