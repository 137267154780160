import { createAsyncThunk } from "@reduxjs/toolkit";
import { FormikHelpers } from "formik";
import * as candidateApi from "./candidateApi";
import statusCodes from "../../utils/statusCodes";
import setFormikErrors from "../../utils/setFormikErrors";

export const createCandidate = createAsyncThunk(
  "candidates/create",
  async (
    {
      params,
      jobId,
      formikBag,
    }: {
      params: FormData;
      jobId: string;
      formikBag?: FormikHelpers<candidateApi.CandidateParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await candidateApi.create(params, jobId);
      const { data } = response;
      if (response.status === statusCodes.created) {
        return data;
      } else {
        if (formikBag) {
          setFormikErrors(data?.errors, formikBag);
        }
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateCandidate = createAsyncThunk(
  "candidates/update",
  async (
    {
      params,
      formikBag,
    }: {
      params: candidateApi.CandidateUpdateParams;
      formikBag?: FormikHelpers<candidateApi.CandidateUpdateParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await candidateApi.update(params);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        if (formikBag) {
          setFormikErrors(data?.errors, formikBag);
        }
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
