import { createSlice } from "@reduxjs/toolkit";
import {
  createDepartment,
  destroyDepartment,
  getDepartments,
  showDepartment,
  updateDepartment,
} from "./departmentActions";
import { RootState } from "../../app/store";
import {
  DepartmentParams,
  DepartmentResponse,
  DepartmentShowResponse,
  DepartmentIndexAttributes,
} from "./departmentApi";
import filterErrorMessage from "../../utils/filterErrorMessage";

export interface initialState {
  initialValues: DepartmentParams;
  department: DepartmentResponse | DepartmentShowResponse | null;
  departments: DepartmentIndexAttributes[];
  isFetching: boolean;
  isSaving: boolean;
  isFinding: boolean;
  isDestroying: boolean;
  isSuccess: boolean;
  isSaveSuccess: boolean;
  isFindingSuccess: boolean;
  isDestroyingSuccess: boolean;
  isError: boolean;
  errorMessage: string | null;
}

export const initialDepartmentValues: DepartmentParams = {
  id: null,
  name: "",
};

export const departmentSlice = createSlice({
  name: "department",
  initialState: {
    initialValues: initialDepartmentValues,
    department: null,
    departments: [],
    isFetching: false,
    isSaving: false,
    isFinding: false,
    isDestroying: false,
    isSuccess: false,
    isSaveSuccess: false,
    isFindingSuccess: false,
    isDestroyingSuccess: false,
    isError: false,
    errorMessage: null,
  } as initialState,

  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.isSaving = false;
      state.isFinding = false;
      state.isDestroying = false;
      state.isSuccess = false;
      state.isSaveSuccess = false;
      state.isFindingSuccess = false;
      state.isDestroyingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
    clearShowState: (state) => {
      state.department = null;
      state.isFindingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
    clearDestroyState: (state) => {
      state.department = null;
      state.isDestroyingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getDepartments.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getDepartments.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.departments = payload.records;
    });
    builder.addCase(getDepartments.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.departments = [];
    });

    builder.addCase(createDepartment.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(createDepartment.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.department = payload;
    });
    builder.addCase(createDepartment.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as DepartmentResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(showDepartment.pending, (state) => {
      state.isFinding = true;
      state.isFindingSuccess = false;
    });
    builder.addCase(showDepartment.fulfilled, (state, { payload }) => {
      state.isFinding = false;
      state.isFindingSuccess = true;
      state.department = payload;
    });
    builder.addCase(showDepartment.rejected, (state, action) => {
      state.isFinding = false;
      state.isError = true;
      const payload = action.payload as DepartmentResponse;
      state.errorMessage = filterErrorMessage(
        payload?.error || payload?.error_messages?.[0]
      );
    });

    builder.addCase(updateDepartment.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(updateDepartment.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.department = payload;
    });
    builder.addCase(updateDepartment.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as DepartmentResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(destroyDepartment.pending, (state) => {
      state.isDestroying = true;
      state.isDestroyingSuccess = false;
    });
    builder.addCase(destroyDepartment.fulfilled, (state) => {
      state.isDestroying = false;
      state.isDestroyingSuccess = true;
    });
    builder.addCase(destroyDepartment.rejected, (state, action) => {
      state.isDestroying = false;
      state.isError = true;
      const payload = action.payload as DepartmentResponse;
      state.errorMessage = filterErrorMessage(
        payload?.error || payload?.error_messages?.[0]
      );
    });
  },
});

export const { clearState, clearShowState, clearDestroyState } =
  departmentSlice.actions;

export const departmentSelector = (state: RootState) => state.department;
