import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/store";
import { clearState, userSelector } from "../redux/user/userSlice";
import { fetchUserByToken } from "../redux/user/userActions";
import { clearLocalStorage, getToken } from "../utils/localStorage";
import Backdrop from "./Backdrop";
import urls from "../utils/urls";

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isUnauthorized, isFinding, currentUser } =
    useAppSelector(userSelector);

  const token = getToken();

  useEffect(() => {
    dispatch(fetchUserByToken());

    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (isUnauthorized) {
      clearLocalStorage();
      if (!currentUser.company) {
        navigate(urls.candidateLogin);
      } else {
        navigate(urls.login);
      }
    }
  }, [isUnauthorized, currentUser]);

  if (!token || (!token && isFinding)) {
    return <Backdrop />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
