import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/store";
import {
  clearDestroyState,
  clearShowState,
  clearState,
  benefitSelector,
  initialBenefitValues,
} from "../redux/benefit/benefitSlice";
import {
  createBenefit,
  showBenefit,
  updateBenefit,
} from "../redux/benefit/benefitActions";
import { toast } from "react-toastify";
import urls from "../utils/urls";
import { useFormik } from "formik";
import {
  Box,
  Typography,
  Paper,
  Grid,
  FormGroup,
  FormControl,
  TextField,
  Tooltip,
} from "@mui/material";
import Backdrop from "../components/Backdrop";
import LoadingButton from "@mui/lab/LoadingButton";
import usePermission from "../hooks/usePermission";
import { texts } from "../utils/texts";

function ManageBenefits() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const hasPermissionToBenefitsCreate = usePermission("benefits.create");
  const hasPermissionToBenefitsUpdate = usePermission("benefits.update");

  const benefitState = useAppSelector(benefitSelector);

  useEffect(() => {
    if (id) {
      dispatch(showBenefit(id));
    }
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(clearState());
      dispatch(clearShowState());
      dispatch(clearDestroyState());
    };
  }, []);

  useEffect(() => {
    if (benefitState.isSaveSuccess) {
      toast.success("Benefício salvo com sucesso");
      navigate(urls.benefits);
    }
  }, [benefitState.isSaveSuccess]);

  const formik = useFormik({
    initialValues: benefitState.benefit
      ? {
          id: benefitState.benefit.id,
          name: benefitState.benefit.name,
        }
      : initialBenefitValues,
    onSubmit: (values, formikBag) => {
      if (values.id) {
        dispatch(
          updateBenefit({
            params: values,
            formikBag: formikBag,
          })
        );
      } else {
        dispatch(
          createBenefit({
            params: values,
            formikBag: formikBag,
          })
        );
      }
    },
    enableReinitialize: true,
  });

  const noHasPermissionToCreateOrUpdate =
    (id && !hasPermissionToBenefitsUpdate) ||
    (!id && !hasPermissionToBenefitsCreate);

  return (
    <Box>
      {benefitState.isFinding && <Backdrop />}
      <Typography variant="h5">
        {id ? "Atualizar benefício" : "Cadastrar novo benefício"}
      </Typography>
      <Paper sx={{ mb: 2, mt: 2, p: { xs: 1, sm: 2 } }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  label="Nome"
                  size="small"
                  required
                  onChange={formik.handleChange("name")}
                  onBlur={formik.handleBlur("name")}
                  value={formik.values.name}
                  error={!!(formik.touched.name && formik.errors.name)}
                  helperText={
                    formik.touched.name && formik.errors.name
                      ? formik.errors.name
                      : null
                  }
                />
              </FormControl>
            </FormGroup>
          </Grid>
        </Grid>

        <Tooltip
          title={noHasPermissionToCreateOrUpdate ? texts.notAuthorized : ""}
        >
          <Box sx={{ width: "fit-content" }}>
            <LoadingButton
              variant="contained"
              type="submit"
              size="large"
              onClick={() => formik.handleSubmit()}
              loading={benefitState.isSaving}
              disabled={noHasPermissionToCreateOrUpdate}
              sx={{ mt: 4 }}
            >
              {id ? "Atualizar" : "Cadastrar"}
            </LoadingButton>
          </Box>
        </Tooltip>
      </Paper>
    </Box>
  );
}

export default ManageBenefits;
