import { AxiosResponse } from "axios";
import { ResponseWithErrors } from "../../interfaces";
import requestHelper from "../../utils/requestHelper";

export interface DepartmentAutocomplete
  extends Omit<DepartmentIndexAttributes, "id"> {
  id: number | null;
  inputValue?: string;
}

export interface DepartmentIndexAttributes {
  id: number;
  name: string;
}

export interface DepartmentIndexResponse extends ResponseWithErrors {
  records: DepartmentIndexAttributes[];
}

export interface DepartmentParams {
  id: number | null;
  name: string | null;
}

export interface DepartmentShowResponse {
  id: number;
  name: string;
}

export interface DepartmentResponse
  extends DepartmentShowResponse,
    ResponseWithErrors {}

const index = async (): Promise<AxiosResponse<DepartmentIndexResponse>> => {
  return await requestHelper.get(`/departments`).catch((e) => e.response);
};

const create = async (
  params: DepartmentParams
): Promise<AxiosResponse<DepartmentResponse>> => {
  return await requestHelper
    .post(`departments`, {
      department: params,
    })
    .catch((e) => e.response);
};

const show = async (
  id: string
): Promise<AxiosResponse<DepartmentShowResponse>> => {
  return await requestHelper.get(`departments/${id}`).catch((e) => e.response);
};

const update = async (
  params: DepartmentParams
): Promise<AxiosResponse<DepartmentResponse>> => {
  return await requestHelper
    .patch(`departments/${params.id}`, {
      department: params,
    })
    .catch((e) => e.response);
};

const destroy = async (
  id: string
): Promise<AxiosResponse<DepartmentShowResponse>> => {
  return await requestHelper
    .delete(`departments/${id}`)
    .catch((e) => e.response);
};

export { index, create, show, update, destroy };
