import React, { useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { FormikProps } from "formik";
import { JobParams } from "../../redux/job/jobApi";

interface IJobPromotion {
  formik: FormikProps<JobParams>;
}

const JobPromotion: React.FC<IJobPromotion> = ({ formik }) => {
  const [jobPromotionExpanded, setJobPromotionExpanded] =
    useState<boolean>(true);

  return (
    <Accordion
      expanded={jobPromotionExpanded}
      onChange={() => setJobPromotionExpanded(!jobPromotionExpanded)}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography fontWeight={"bold"}>Divulgação</Typography>
      </AccordionSummary>
      <AccordionDetails></AccordionDetails>
    </Accordion>
  );
};

export default JobPromotion;
