import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  Tooltip,
  useTheme,
} from "@mui/material";
import {
  PermissionIndexAttributes,
  index as getPermissions,
} from "../redux/permission/permissionApi";
import statusCodes from "../utils/statusCodes";
import { permissionHelpers, permissionNames } from "../utils/constants";
import { FormikProps } from "formik";
import { ExpandLess, ExpandMore, HelpOutline } from "@mui/icons-material";

interface IPermissionTreeView {
  formik: FormikProps<any>;
  isEditable: boolean;
  onChangePermissions?: (permissionIds: Array<number>) => void;
}

const PermissionTreeView: React.FC<IPermissionTreeView> = ({
  formik,
  isEditable,
  onChangePermissions,
}) => {
  const theme = useTheme();

  const [permissions, setPermissions] = useState<PermissionIndexAttributes[]>(
    []
  );

  useEffect(() => {
    handleGetPermissions();
  }, []);

  const handleGetPermissions = async () => {
    const response = await getPermissions();
    if (response.status === statusCodes.ok) {
      setPermissions(response.data.records);
    }
  };

  const isChecked = (permissionId: number) => {
    return formik.values.permission_ids.includes(permissionId);
  };

  function getAllChildIDs(permission: PermissionIndexAttributes): number[] {
    let childIDs: number[] = [];

    if (permission.children && permission.children.length > 0) {
      for (const child of permission.children) {
        childIDs.push(child.id);

        childIDs = childIDs.concat(getAllChildIDs(child));
      }
    }

    return childIDs;
  }

  const onChange = (
    checked: boolean,
    permission: PermissionIndexAttributes
  ) => {
    let permissionIds: Array<number> = [];
    const childIDs = getAllChildIDs(permission);
    if (checked) {
      permissionIds = [
        ...formik.values.permission_ids,
        ...childIDs,
        permission.id,
      ];
    } else {
      permissionIds = formik.values.permission_ids.filter(
        (permissionId: number) =>
          ![...childIDs, permission.id].includes(permissionId)
      );
    }
    permissionIds = [...new Set(permissionIds)];
    formik.setFieldValue("permission_ids", permissionIds);
    formik.setFieldTouched("permission_ids", true);
    if (onChangePermissions) {
      onChangePermissions(permissionIds);
    }
  };

  const renderLabel = (permission: PermissionIndexAttributes) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {permissionNames[permission.name]}
        <Tooltip title={permissionHelpers[permission.name]}>
          <HelpOutline
            fontSize="small"
            sx={{ color: theme.palette.grey[500] }}
          />
        </Tooltip>
      </Box>
    );
  };

  const renderArrows = (
    permission: PermissionIndexAttributes,
    isMainParent: boolean
  ) => {
    if (permission.children.length === 0) {
      return null;
    }

    return isChecked(permission.id) ? (
      <ExpandLess
        sx={{
          color: "rgba(0, 0, 0, 0.54)",
          cursor: "pointer",
          ml: isMainParent ? 0 : -4,
        }}
        onClick={() => isEditable && onChange(false, permission)}
      />
    ) : (
      <ExpandMore
        sx={{
          color: "rgba(0, 0, 0, 0.54)",
          cursor: "pointer",
          ml: isMainParent ? 0 : -4,
        }}
        onClick={() => isEditable && onChange(true, permission)}
      />
    );
  };

  const renderCheckbox = (permission: PermissionIndexAttributes) => {
    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked(permission.id)}
              onChange={(e, checked) => onChange(checked, permission)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={renderLabel(permission)}
          disabled={!isEditable}
        />
      </FormGroup>
    );
  };

  const renderChildren = (
    permission: PermissionIndexAttributes,
    isFirstChildren: boolean
  ) => {
    return (
      <Collapse
        in={isChecked(permission.id)}
        sx={{ ml: isFirstChildren ? 8 : 4 }}
      >
        {permission.children.map((child) => {
          return (
            <Box key={`permission-child-${child.name}`}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {renderArrows(child, false)}
                {renderCheckbox(child)}
              </Box>
              {renderChildren(child, false)}
            </Box>
          );
        })}
      </Collapse>
    );
  };

  return (
    <Grid container spacing={2}>
      {permissions.map((permission) => (
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          xl={3}
          key={`permission-${permission.name}`}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {renderArrows(permission, true)}
            {renderCheckbox(permission)}
          </Box>
          {renderChildren(permission, true)}
        </Grid>
      ))}
    </Grid>
  );
};

export default PermissionTreeView;
