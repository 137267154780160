import React, { useState } from "react";
import {
  Accessible,
  Business,
  Computer,
  Place,
  Share,
  Work,
} from "@mui/icons-material";
import { Box, Button, Grid, Icon, Snackbar, Typography } from "@mui/material";
import { format } from "date-fns/format";
import CopyToClipboard from "react-copy-to-clipboard";
import { JobData } from "./Job";
import { JobResponse, JobShowResponse } from "../../redux/job/jobApi";
import { maskCurrency } from "../../utils/masks";
import { states, workingModels, workingTypes } from "../../utils/constants";

interface IJobHeader {
  job: JobData | JobResponse | JobShowResponse | null;
}

const JobHeader: React.FC<IJobHeader> = ({ job }) => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const handleCopyUrl = () => {
    setSnackbarOpen(true);
  };

  return job?.name ? (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography variant="h4" sx={{ wordWrap: "break-word" }}>
          {job.name}
        </Typography>
        <Box>
          <CopyToClipboard
            text={`${window.location.origin}${window.location.pathname}`}
            onCopy={handleCopyUrl}
          >
            <Button variant="outlined" size="large" startIcon={<Share />}>
              Copiar Link
            </Button>
          </CopyToClipboard>
          <Snackbar
            message="Link Copiado"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            autoHideDuration={2000}
            onClose={() => setSnackbarOpen(false)}
            open={snackbarOpen}
          />
        </Box>
      </Box>

      {job?.published_at && (
        <Typography sx={{ mt: 1 }}>
          Publicado em {format(job.published_at, "dd 'de' MMMM 'de' yyyy")}
        </Typography>
      )}

      <Grid container spacing={2} sx={{ mt: 1 }}>
        {job?.state && (
          <Grid item xs={12} sm={12} md={5}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Place
                sx={{ pb: "4px", color: (theme) => theme.palette.grey[600] }}
              />
              <Typography>
                {job?.city ? `${job.city}, ` : ""}
                {job?.city ? job.state : states[job.state]}
              </Typography>
            </Box>{" "}
          </Grid>
        )}

        {job?.working_type_visible && job?.working_type && (
          <Grid item xs={12} sm={12} md={5}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Work
                sx={{ pb: "4px", color: (theme) => theme.palette.grey[600] }}
              />
              <Typography>{workingTypes[job.working_type]}</Typography>
            </Box>
          </Grid>
        )}

        {job?.working_model && (
          <Grid item xs={12} sm={12} md={5}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {job.working_model === "on_site" ? (
                <Business
                  sx={{
                    pb: "4px",
                    color: (theme) => theme.palette.grey[600],
                  }}
                />
              ) : (
                <Computer
                  sx={{
                    pb: "2px",
                    color: (theme) => theme.palette.grey[600],
                  }}
                />
              )}
              <Typography>{workingModels[job.working_model]}</Typography>
            </Box>
          </Grid>
        )}

        {job?.salary_range_visible &&
          (job?.minimum_salary_range || job?.maximum_salary_range) && (
            <Grid item xs={12} sm={12} md={5}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Icon
                  baseClassName="fas"
                  className="fa-money-bill"
                  sx={{
                    color: (theme) => theme.palette.grey[600],
                    pr: "5px",
                  }}
                  fontSize="small"
                />
                <Typography>
                  {job.minimum_salary_range &&
                    maskCurrency(job.minimum_salary_range, {
                      style: "currency",
                      currency: "BRL",
                    })}
                  {job.minimum_salary_range && job.maximum_salary_range
                    ? " até "
                    : null}
                  {job.maximum_salary_range &&
                    maskCurrency(job.maximum_salary_range, {
                      style: "currency",
                      currency: "BRL",
                    })}
                </Typography>
              </Box>
            </Grid>
          )}

        {job?.is_pwd && (
          <Grid item xs={12} sm={12} md={5}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Accessible
                sx={{ pb: "4px", color: (theme) => theme.palette.grey[600] }}
              />
              <Typography>Vaga exclusiva para PCD</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  ) : null;
};

export default JobHeader;
