import React from 'react'
import {
  Backdrop as BackdropCore, CircularProgress, useTheme,
} from "@mui/material";

function Backdrop() {
  const theme = useTheme();

  return (
    <BackdropCore
        sx={{
          color: theme.palette.common.white,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </BackdropCore>
  )
}

export default Backdrop