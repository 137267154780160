import { createSlice } from "@reduxjs/toolkit";
import {
  createBenefit,
  destroyBenefit,
  getBenefits,
  showBenefit,
  updateBenefit,
} from "./benefitActions";
import { RootState } from "../../app/store";
import {
  BenefitParams,
  BenefitResponse,
  BenefitShowResponse,
  BenefitIndexAttributes,
} from "./benefitApi";
import filterErrorMessage from "../../utils/filterErrorMessage";

export interface initialState {
  initialValues: BenefitParams;
  benefit: BenefitResponse | BenefitShowResponse | null;
  benefits: BenefitIndexAttributes[];
  isFetching: boolean;
  isSaving: boolean;
  isFinding: boolean;
  isDestroying: boolean;
  isSuccess: boolean;
  isSaveSuccess: boolean;
  isFindingSuccess: boolean;
  isDestroyingSuccess: boolean;
  isError: boolean;
  errorMessage: string | null;
}

export const initialBenefitValues: BenefitParams = {
  id: null,
  name: "",
};

export const benefitSlice = createSlice({
  name: "benefit",
  initialState: {
    initialValues: initialBenefitValues,
    benefit: null,
    benefits: [],
    isFetching: false,
    isSaving: false,
    isFinding: false,
    isDestroying: false,
    isSuccess: false,
    isSaveSuccess: false,
    isFindingSuccess: false,
    isDestroyingSuccess: false,
    isError: false,
    errorMessage: null,
  } as initialState,

  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.isSaving = false;
      state.isFinding = false;
      state.isDestroying = false;
      state.isSuccess = false;
      state.isSaveSuccess = false;
      state.isFindingSuccess = false;
      state.isDestroyingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
    clearShowState: (state) => {
      state.benefit = null;
      state.isFindingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
    clearDestroyState: (state) => {
      state.benefit = null;
      state.isDestroyingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getBenefits.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getBenefits.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.benefits = payload.records;
    });
    builder.addCase(getBenefits.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.benefits = [];
    });

    builder.addCase(createBenefit.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(createBenefit.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.benefit = payload;
    });
    builder.addCase(createBenefit.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as BenefitResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(showBenefit.pending, (state) => {
      state.isFinding = true;
      state.isFindingSuccess = false;
    });
    builder.addCase(showBenefit.fulfilled, (state, { payload }) => {
      state.isFinding = false;
      state.isFindingSuccess = true;
      state.benefit = payload;
    });
    builder.addCase(showBenefit.rejected, (state, action) => {
      state.isFinding = false;
      state.isError = true;
      const payload = action.payload as BenefitResponse;
      state.errorMessage = filterErrorMessage(
        payload?.error || payload?.error_messages?.[0]
      );
    });

    builder.addCase(updateBenefit.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(updateBenefit.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.benefit = payload;
    });
    builder.addCase(updateBenefit.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as BenefitResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(destroyBenefit.pending, (state) => {
      state.isDestroying = true;
      state.isDestroyingSuccess = false;
    });
    builder.addCase(destroyBenefit.fulfilled, (state) => {
      state.isDestroying = false;
      state.isDestroyingSuccess = true;
    });
    builder.addCase(destroyBenefit.rejected, (state, action) => {
      state.isDestroying = false;
      state.isError = true;
      const payload = action.payload as BenefitResponse;
      state.errorMessage = filterErrorMessage(
        payload?.error || payload?.error_messages?.[0]
      );
    });
  },
});

export const { clearState, clearShowState, clearDestroyState } =
  benefitSlice.actions;

export const benefitSelector = (state: RootState) => state.benefit;
