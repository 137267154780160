import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

function PageNotFound() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Box sx={{ textAlign: "center", pb: 10 }}>
        <Typography
          variant="h1"
          color={theme.palette.common.white}
          fontSize="14vw"
        >
          404
        </Typography>
        <Typography
          variant="h4"
          color={theme.palette.common.white}
          fontSize="3vw"
        >
          Página não encontrada
        </Typography>
      </Box>
    </Box>
  );
}

export default PageNotFound;
