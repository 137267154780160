import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface initialState {
  open: boolean;
}

export const navSlice = createSlice({
  name: "nav",
  initialState: {
    open: true,
  } as initialState,

  reducers: {
    closeNav: (state) => {
      state.open = false;
    },
    openNav: (state) => {
      state.open = true;
    },
  },
});

export const { closeNav, openNav } = navSlice.actions;

export const navSelector = (state: RootState) => state.nav;
