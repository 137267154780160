import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  ForwardRefRenderFunction,
} from "react";
import { alpha, styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

interface IMenuDropdown {
  id: string;
  children: React.ReactNode;
}

export interface IMenuDropdownRef {
  open: (e: React.MouseEvent<HTMLElement>) => void;
  close: () => void;
}

export const MenuItemDropdown = styled(MenuItem)<{
  component?: React.ElementType;
  href?: string;
  target?: string;
  download?: boolean;
}>(({ theme }) => ({
  "&": {
    fontSize: 16,
    letterSpacing: "0.0168em",
    whiteSpace: "normal",
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.main,
    },
  },
}));

const MenuDropdown: ForwardRefRenderFunction<
  IMenuDropdownRef,
  IMenuDropdown
> = ({ id, children }, ref) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useImperativeHandle(
    ref,
    () => ({
      open: (e) => {
        setAnchorEl(e.currentTarget);
      },
      close: handleClose,
    }),
    []
  );

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      disableScrollLock
      disableAutoFocusItem
    >
      {children}
    </Menu>
  );
};

export default forwardRef(MenuDropdown);
