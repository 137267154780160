import { AuthenticateResponse } from "../redux/user/userApi";

const TOKEN = "app@token";

export const saveToken = (token: {
  access_token: string;
  refresh_token: string;
}) => {
  localStorage.setItem(TOKEN, JSON.stringify(token));
};

export const getToken = (): AuthenticateResponse | null => {
  const token = localStorage.getItem(TOKEN);
  if (token) {
    return JSON.parse(token);
  }
  return null;
};

export const clearLocalStorage = () => {
  localStorage.removeItem(TOKEN);
};
