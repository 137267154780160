import { FormikHelpers } from "formik";
import capitalize from "./capitalize";
import { Errors } from "../interfaces";

export default (errors: Errors | undefined, formikBag: FormikHelpers<any>) => {
  if (errors && formikBag) {
    Object.entries(errors).map((error) => {
      const [fieldName, errorMessage] = error;
      if (errorMessage?.[0] && typeof errorMessage[0] === "string") {
        formikBag.setFieldError(fieldName, capitalize(errorMessage[0]));
      } else {
        formikBag.setFieldError(fieldName, errorMessage);
      }
      formikBag.setFieldTouched(fieldName, true, false);
    });
  }
};
