import { createAsyncThunk } from "@reduxjs/toolkit";
import { FormikHelpers } from "formik";
import * as benefitApi from "./benefitApi";
import statusCodes from "../../utils/statusCodes";
import setFormikErrors from "../../utils/setFormikErrors";

export const getBenefits = createAsyncThunk(
  "benefits/index",
  async (_params, thunkAPI) => {
    try {
      const response = await benefitApi.index();
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createBenefit = createAsyncThunk(
  "benefits/create",
  async (
    {
      params,
      formikBag,
    }: {
      params: benefitApi.BenefitParams;
      formikBag?: FormikHelpers<benefitApi.BenefitParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await benefitApi.create(params);
      const { data } = response;
      if (response.status === statusCodes.created) {
        return data;
      } else {
        if (formikBag) {
          setFormikErrors(data?.errors, formikBag);
        }
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const showBenefit = createAsyncThunk(
  "benefits/show",
  async (id: string, thunkAPI) => {
    try {
      const response = await benefitApi.show(id);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateBenefit = createAsyncThunk(
  "benefits/update",
  async (
    {
      params,
      formikBag,
    }: {
      params: benefitApi.BenefitParams;
      formikBag: FormikHelpers<benefitApi.BenefitParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await benefitApi.update(params);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const destroyBenefit = createAsyncThunk(
  "benefits/destroy",
  async (id: string, thunkAPI) => {
    try {
      const response = await benefitApi.destroy(id);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
