import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { destroyJob, getJobs } from "../redux/job/jobActions";
import { useAppDispatch, useAppSelector } from "../app/store";
import { userSelector } from "../redux/user/userSlice";
import { jobSelector } from "../redux/job/jobSlice";
import { Link } from "react-router-dom";
import { Delete, Edit, Group, Visibility } from "@mui/icons-material";
import { toast } from "react-toastify";
import { jobStatuses, workingModels, workingTypes } from "../utils/constants";
import urls from "../utils/urls";
import usePermission from "../hooks/usePermission";
import { texts } from "../utils/texts";

function Jobs() {
  const dispatch = useAppDispatch();
  const { width, height } = useWindowDimensions();
  const tableRef = useRef<HTMLDivElement>(null);

  const hasPermissionToJobsShow = usePermission("jobs.show");
  const hasPermissionToJobsCreate = usePermission("jobs.create");
  const hasPermissionToJobsUpdate = usePermission("jobs.update");
  const hasPermissionToJobsDestroy = usePermission("jobs.destroy");

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<GridRowId | null>(null);
  const [offsetTop, setOffsetTop] = useState<number>(0);

  const userState = useAppSelector(userSelector);
  const jobState = useAppSelector(jobSelector);

  useEffect(() => {
    if (tableRef.current) {
      setOffsetTop(tableRef.current.offsetTop);
    }
  }, [tableRef.current, width, height]);

  useEffect(() => {
    if (userState.currentUser.company?.id) {
      dispatch(getJobs(userState.currentUser.company.id));
    }
  }, [userState.currentUser.company?.id]);

  const handleOpenDeleteDialog = (id: GridRowId) => {
    setIdToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setIdToDelete(null);
  };

  const handleDelete = async () => {
    if (idToDelete) {
      await dispatch(destroyJob(idToDelete.toString())).then((value) => {
        if (value.meta.requestStatus !== "rejected") {
          toast.success("Vaga excluída com sucesso");
          if (userState.currentUser.company) {
            dispatch(getJobs(userState.currentUser.company.id));
          }
        }
      });
    }
    handleCloseDeleteDialog();
  };

  const jobs = useMemo(() => {
    return jobState.jobs.map((job) => {
      return {
        ...job,
        status: jobStatuses[job.status],
        working_type: job.working_type ? workingTypes[job.working_type] : "",
        working_model: job.working_model
          ? workingModels[job.working_model]
          : "",
      };
    });
  }, [jobState.jobs]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Título da vaga",
      flex: 1,
    },
    {
      field: "working_type",
      headerName: "Regime de contratação",
      width: 200,
    },
    {
      field: "working_model",
      headerName: "Modelo de Trabalho",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={
            params.value.toLowerCase() === "publicado" ? "success" : "warning"
          }
          size="small"
          variant="outlined"
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      width: 200,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const actions = [
          <Link
            key={`show-${id}`}
            to={urls.companyJobPage
              .replace(":slug", userState.currentUser.company?.slug || "")
              .replace(":job_id", id.toString())}
            target="_blank"
          >
            <Tooltip title="Ver no site">
              <GridActionsCellItem
                icon={<Visibility />}
                label="Show"
                className="textPrimary"
                // color="primary"
              />
            </Tooltip>
          </Link>,
          <Tooltip
            title={
              hasPermissionToJobsShow ? "Ver candidatos" : texts.notAuthorized
            }
          >
            <Box>
              <GridActionsCellItem
                icon={
                  <Link
                    key={`show-${id}`}
                    to={urls.candidatesJob.replace(":id", id.toString())}
                    style={{ height: "24px" }}
                  >
                    <Group />
                  </Link>
                }
                label="Show"
                className="textPrimary"
                disabled={!hasPermissionToJobsShow}
                // color="primary"
              />
            </Box>
          </Tooltip>,
          <Tooltip
            key={`edit-${id}`}
            title={
              hasPermissionToJobsShow || hasPermissionToJobsUpdate
                ? "Atualizar"
                : texts.notAuthorized
            }
          >
            <Box>
              <GridActionsCellItem
                icon={
                  <Link
                    to={urls.manageJob.replace(":id", id.toString())}
                    style={{ height: "24px" }}
                  >
                    <Edit />
                  </Link>
                }
                label="Edit"
                className="textPrimary"
                disabled={
                  !hasPermissionToJobsShow && !hasPermissionToJobsUpdate
                }
                // color="primary"
              />
            </Box>
          </Tooltip>,
          <Box key={`delete-${id}`}>
            <Tooltip
              title={
                hasPermissionToJobsDestroy ? "Excluir" : texts.notAuthorized
              }
            >
              <Box>
                <GridActionsCellItem
                  icon={<Delete />}
                  label="Delete"
                  onClick={() => handleOpenDeleteDialog(id)}
                  disabled={!hasPermissionToJobsDestroy}
                  // color="error"
                />
              </Box>
            </Tooltip>
            <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
              <DialogTitle>Confirmar exclusão</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem certeza de que deseja excluir esta vaga?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={handleCloseDeleteDialog}>
                  Cancelar
                </Button>
                <LoadingButton
                  variant="contained"
                  onClick={handleDelete}
                  color="error"
                  loading={jobState.isDestroying}
                >
                  Excluir
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </Box>,
        ];

        return actions;
      },
    },
  ];

  return (
    <Box sx={{ height: `calc(100% - ${offsetTop}px)` }}>
      <Typography variant="h5">Vagas</Typography>
      <Box sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title={!hasPermissionToJobsCreate ? texts.notAuthorized : ""}>
          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              size="large"
              disabled={!hasPermissionToJobsCreate}
            >
              <Link to={urls.newJob}>Adicionar nova vaga</Link>
            </LoadingButton>
          </Box>
        </Tooltip>
      </Box>

      <Box style={{ height: "100%", width: "100%", minWidth: 0 }}>
        <DataGrid
          ref={tableRef}
          rows={jobs}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick
          autoHeight
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within":
              {
                outline: "none !important",
              },
          }}
          loading={jobState.isFetching}
        />
      </Box>
    </Box>
  );
}

export default Jobs;
