import { format } from "date-fns/format";
import { isDate } from "date-fns/isDate";
import { parseISO } from "date-fns/parseISO";

export const formatDate = (date: any, time: boolean = false): string => {
  let parsedDate = date;
  if (!isDate(date)) {
    parsedDate = parseISO(date);
  }
  if (time) {
    return format(parsedDate, "dd/MM/yyyy HH:mm");
  }
  return format(parsedDate, "dd/MM/yyyy");
};

export const toDate = (string: string): Date => {
  const stringSplitted = string.split("/");
  return new Date(
    parseInt(stringSplitted[2]),
    parseInt(stringSplitted[1]) - 1,
    parseInt(stringSplitted[0])
  );
};

export const formatCurrency = (value: string): string => {
  return value.replaceAll(".", "").replaceAll(",", ".");
};
