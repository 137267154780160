import { AxiosResponse } from "axios";
import { ResponseWithErrors } from "../../interfaces";
import requestHelper from "../../utils/requestHelper";

export interface BenefitAutocomplete
  extends Omit<BenefitIndexAttributes, "id"> {
  id: number | null;
  inputValue?: string;
}

export interface BenefitIndexAttributes {
  id: number;
  name: string;
}

export interface BenefitIndexResponse extends ResponseWithErrors {
  records: BenefitIndexAttributes[];
}

export interface BenefitParams {
  id: number | null;
  name: string | null;
}

export interface BenefitShowResponse {
  id: number;
  name: string;
}

export interface BenefitResponse
  extends BenefitShowResponse,
    ResponseWithErrors {}

const index = async (): Promise<AxiosResponse<BenefitIndexResponse>> => {
  return await requestHelper.get(`/benefits`).catch((e) => e.response);
};

const create = async (
  params: BenefitParams
): Promise<AxiosResponse<BenefitResponse>> => {
  return await requestHelper
    .post(`benefits`, {
      benefit: params,
    })
    .catch((e) => e.response);
};

const show = async (
  id: string
): Promise<AxiosResponse<BenefitShowResponse>> => {
  return await requestHelper.get(`benefits/${id}`).catch((e) => e.response);
};

const update = async (
  params: BenefitParams
): Promise<AxiosResponse<BenefitResponse>> => {
  return await requestHelper
    .patch(`benefits/${params.id}`, {
      benefit: params,
    })
    .catch((e) => e.response);
};

const destroy = async (
  id: string
): Promise<AxiosResponse<BenefitShowResponse>> => {
  return await requestHelper.delete(`benefits/${id}`).catch((e) => e.response);
};

export { index, create, show, update, destroy };
