import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/store";
import {
  clearShowState,
  clearState,
  userSelector,
} from "../redux/user/userSlice";
import { inviteUser, showUser, updateUser } from "../redux/user/userActions";
import { toast } from "react-toastify";
import urls from "../utils/urls";
import { FormikHelpers, useFormik } from "formik";
import {
  Box,
  Typography,
  Paper,
  Grid,
  FormGroup,
  FormControl,
  TextField,
  SelectChangeEvent,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Backdrop from "../components/Backdrop";
import LoadingButton from "@mui/lab/LoadingButton";
import PermissionTreeView from "../components/PermissionTreeView";
import { profileSelector } from "../redux/profile/profileSlice";
import { clearShowState as clearProfileShowState } from "../redux/profile/profileSlice";
import { getProfiles } from "../redux/profile/profileActions";
import Select from "../components/Select";
import { UserInviteParams, UserParams } from "../redux/user/userApi";
import usePermission from "../hooks/usePermission";
import { texts } from "../utils/texts";
import { userStatusOptions } from "../utils/constants";

function ManageUsers() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const hasPermissionToUsersUpdate = usePermission("users.update");

  const userState = useAppSelector(userSelector);
  const profileState = useAppSelector(profileSelector);

  useEffect(() => {
    if (id) {
      dispatch(showUser(id));
    }
    dispatch(getProfiles());
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(clearState());
      dispatch(clearShowState());
      dispatch(clearProfileShowState());
    };
  }, []);

  useEffect(() => {
    if (userState.isSaveSuccess) {
      toast.success("Usuário salvo com sucesso");
      navigate(urls.users);
    }
  }, [userState.isSaveSuccess]);

  const formik = useFormik({
    initialValues: {
      first_name: userState.user?.first_name,
      last_name: userState.user?.last_name,
      email: userState.user?.email,
      status: userState.user?.status,
      profile_id: userState.user?.profile?.id || "Personalizado",
      permission_ids: userState.user?.permission_ids || [],
    } as UserInviteParams,
    onSubmit: (values, formikBag) => {
      if (id) {
        dispatch(
          updateUser({
            userId: id,
            params: {
              ...values,
              profile_id:
                values.profile_id === "Personalizado"
                  ? null
                  : values.profile_id,
            },
            formikBag: formikBag as FormikHelpers<UserParams>,
          })
        );
      } else {
        dispatch(
          inviteUser({
            params: {
              ...(values as UserInviteParams),
              profile_id:
                values.profile_id === "Personalizado"
                  ? null
                  : values.profile_id,
            },
            formikBag,
          })
        );
      }
    },
    enableReinitialize: true,
  });

  const onChangeProfile = (e: SelectChangeEvent<unknown>) => {
    formik.setFieldValue("profile_id", e.target.value);
    if (e.target.value !== "Personalizado") {
      formik.setFieldValue(
        "permission_ids",
        profileState.profiles.find(
          (profile) => profile.id.toString() === e.target.value?.toString()
        )?.permission_ids || []
      );
    }
  };

  const onChangePermissions = (permissionIds: Array<number>) => {
    let foundProfile = false;
    const permissionIdsSorted = permissionIds.slice().sort();
    for (const profile of profileState.profiles) {
      if (
        JSON.stringify(profile.permission_ids.slice().sort()) ===
        JSON.stringify(permissionIdsSorted)
      ) {
        formik.setFieldValue("profile_id", profile.id);
        foundProfile = true;
        break;
      }
    }

    if (!foundProfile) {
      formik.setFieldValue("profile_id", "Personalizado");
    }
  };

  return (
    <Box>
      {userState.isFinding && <Backdrop />}
      <Typography variant="h5">
        {id ? "Atualizar usuário" : "Convidar novo usuário"}
      </Typography>
      <Paper sx={{ mb: 2, mt: 2, p: { xs: 1, sm: 2 } }}>
        {(!id || userState.user) && (
          <Grid container spacing={2}>
            {id && (
              <>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        label="Nome"
                        size="small"
                        required
                        onChange={formik.handleChange("first_name")}
                        onBlur={formik.handleBlur("first_name")}
                        value={formik.values.first_name}
                        error={
                          !!(
                            formik.touched.first_name &&
                            formik.errors.first_name
                          )
                        }
                        helperText={
                          formik.touched.first_name && formik.errors.first_name
                            ? formik.errors.first_name
                            : null
                        }
                        disabled={!!id}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        label="Sobrenome"
                        size="small"
                        required
                        onChange={formik.handleChange("last_name")}
                        onBlur={formik.handleBlur("last_name")}
                        value={formik.values.last_name}
                        error={
                          !!(
                            formik.touched.last_name && formik.errors.last_name
                          )
                        }
                        helperText={
                          formik.touched.last_name && formik.errors.last_name
                            ? formik.errors.last_name
                            : null
                        }
                        disabled={!!id}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
              </>
            )}

            <Grid item xs={12} md={4}>
              <FormGroup>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    label="E-mail"
                    size="small"
                    required
                    onChange={formik.handleChange("email")}
                    onBlur={formik.handleBlur("email")}
                    value={formik.values.email}
                    error={!!(formik.touched.email && formik.errors.email)}
                    helperText={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : null
                    }
                    disabled={!!id}
                  />
                </FormControl>
              </FormGroup>
            </Grid>

            {id && (
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormControl
                    fullWidth
                    error={!!(formik.touched.status && formik.errors.status)}
                    required
                  >
                    <Select
                      labelId="status-label"
                      id="status"
                      label="Status"
                      size="small"
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        formik.setFieldValue("status", e.target.value);
                      }}
                      onBlur={formik.handleBlur("status")}
                      value={formik.values.status || ""}
                      helperText={
                        formik.touched.status && formik.errors.status
                          ? formik.errors.status
                          : null
                      }
                    >
                      {userStatusOptions.map((status) => (
                        <MenuItem key={status.id} value={status.id}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormGroup>
              </Grid>
            )}

            <Grid item xs={12} md={id ? 6 : 8}>
              <FormGroup>
                <FormControl
                  fullWidth
                  error={
                    !!(formik.touched.profile_id && formik.errors.profile_id)
                  }
                  required
                >
                  <Select
                    labelId="profile-label"
                    id="profile"
                    label="Perfil"
                    size="small"
                    onChange={onChangeProfile}
                    onBlur={formik.handleBlur("profile_id")}
                    value={formik.values.profile_id || ""}
                    helperText={
                      formik.touched.profile_id && formik.errors.profile_id
                        ? formik.errors.profile_id
                        : null
                    }
                  >
                    {profileState.profiles.map((profile) => (
                      <MenuItem key={profile.id} value={profile.id}>
                        {profile.name}
                      </MenuItem>
                    ))}
                    <MenuItem value={"Personalizado"}>Personalizado</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
          </Grid>
        )}

        <Box sx={{ mt: 4 }}>
          <PermissionTreeView
            formik={formik}
            isEditable={true}
            onChangePermissions={onChangePermissions}
          />
        </Box>

        <Tooltip title={!hasPermissionToUsersUpdate ? texts.notAuthorized : ""}>
          <Box sx={{ width: "fit-content" }}>
            <LoadingButton
              variant="contained"
              type="submit"
              size="large"
              onClick={() => formik.handleSubmit()}
              loading={userState.isSaving}
              disabled={!hasPermissionToUsersUpdate}
              sx={{ mt: 4 }}
            >
              {id ? "Atualizar" : "Convidar"}
            </LoadingButton>
          </Box>
        </Tooltip>
      </Paper>
    </Box>
  );
}

export default ManageUsers;
