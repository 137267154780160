import React from "react";
import Banner from "./Banner";
import Logo from "./Logo";
import { Box, Typography } from "@mui/material";

interface IHeader {
  banner?: string;
  logo?: string;
  name?: string;
  slogan?: string;
  slug?: string;
}

const Header: React.FC<IHeader> = ({ banner, logo, name, slogan, slug }) => {
  return (
    <>
      <Banner src={banner?.toString()} />
      <Logo src={logo?.toString()} banner={banner?.toString()} slug={slug} />

      {name && (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Typography variant="h4">{name}</Typography>
        </Box>
      )}
      {slogan && (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Typography color="grey">
            <i>{slogan}</i>
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Header;
