import React, { useEffect } from "react";
import LoginCardContainer from "../components/LoginCardContainer";
import {
  Alert,
  FormControl,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import { colors } from "../assets/themes";
import { LoadingButton } from "@mui/lab";
import Yup from "../utils/yup";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../app/store";
import {
  requestNewPassword,
  resetPassword,
} from "../redux/password/passwordAction";
import { clearState, passwordSelector } from "../redux/password/passwordSlice";
import { useNavigate, useParams } from "react-router-dom";
import PasswordInput from "../components/Input/PasswordInput";
import urls from "../utils/urls";
import { toast } from "react-toastify";

const RecoverPasswordSchema = Yup.object().shape({
  password: Yup.string().min(8).required(),
  password_confirmation: Yup.string()
    .test("passwords-match", "Senhas não conferem", function (value) {
      return this.parent.password === value;
    })
    .required(),
});

const RecoverPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { token } = useParams<{ token: string }>();

  const passwordState = useAppSelector(passwordSelector);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (passwordState.isSuccess) {
      toast.success("Senha alterada com sucesso");
      navigate(urls.login);
    }
  }, [passwordState.isSuccess]);

  useEffect(() => {
    if (passwordState.errorMessage) {
      toast.error(passwordState.errorMessage);
    }
  }, [passwordState.errorMessage]);

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: RecoverPasswordSchema,
    onSubmit: (values, formikBag) => {
      if (token) {
        dispatch(resetPassword({ token, params: values, formikBag }));
      }
    },
    enableReinitialize: true,
  });

  return (
    <LoginCardContainer>
      <Typography color={colors.grey} fontSize={15} fontWeight="400">
        Crie sua nova senha
      </Typography>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <FormGroup sx={{ marginTop: 2 }}>
          <FormControl fullWidth>
            <PasswordInput
              label="Nova Senha"
              onChange={formik.handleChange("password")}
              onBlur={formik.handleBlur("password")}
              value={formik.values.password}
              error={!!(formik.touched.password && formik.errors.password)}
              helperText={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : null
              }
            />
          </FormControl>
        </FormGroup>

        <FormGroup sx={{ marginTop: 2 }}>
          <FormControl fullWidth>
            <PasswordInput
              label="Confirmar Senha"
              onChange={formik.handleChange("password_confirmation")}
              onBlur={formik.handleBlur("password_confirmation")}
              value={formik.values.password_confirmation}
              error={
                !!(
                  formik.touched.password_confirmation &&
                  formik.errors.password_confirmation
                )
              }
              helperText={
                formik.touched.password_confirmation &&
                formik.errors.password_confirmation
                  ? formik.errors.password_confirmation
                  : null
              }
            />
          </FormControl>
        </FormGroup>

        <FormGroup sx={{ marginTop: 4 }}>
          <FormControl fullWidth>
            <LoadingButton
              variant="contained"
              loading={passwordState.isSaving}
              type="submit"
              size="large"
            >
              Confirmar
            </LoadingButton>
          </FormControl>
        </FormGroup>
      </form>
    </LoginCardContainer>
  );
};

export default RecoverPassword;
