import { AxiosResponse } from "axios";
import { ResponseWithErrors } from "../../interfaces";
import requestHelper from "../../utils/requestHelper";

export interface RequestNewPasswordParams {
  email: string;
}

export interface ResetPasswordParams {
  password: string;
  password_confirmation: string;
}

export interface RequestNewPasswordResponse extends ResponseWithErrors {}

export interface ResetPasswordResponse extends ResponseWithErrors {}

const requestNewPassword = async (
  params: RequestNewPasswordParams
): Promise<AxiosResponse<RequestNewPasswordResponse>> => {
  return await requestHelper
    .post(`users/password`, params)
    .catch((e) => e.response);
};

const resetPassword = async (
  token: string,
  params: ResetPasswordParams
): Promise<AxiosResponse<ResetPasswordResponse>> => {
  return await requestHelper
    .patch(`users/password/${token}`, params)
    .catch((e) => e.response);
};

export { requestNewPassword, resetPassword };
