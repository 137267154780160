import React, { useEffect, useRef } from "react";
import { Box, useTheme } from "@mui/material";
import useWindowDimensions from "../hooks/useWindowDimensions";

interface ISticky {
  offsetTop: number;
  children: React.ReactNode;
}

const Sticky: React.FC<ISticky> = ({ offsetTop, children }) => {
  const theme = useTheme();
  const { width } = useWindowDimensions();

  const containerRef = useRef<HTMLDivElement>(null);

  const isMobile = width < theme.breakpoints.values.md;

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current?.style) {
        const offset = window.scrollY;
        if (offset > offsetTop && !isMobile) {
          containerRef.current.style.paddingTop = `${offset - offsetTop}px`;
        } else {
          containerRef.current.style.paddingTop = "0";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef.current, isMobile]);

  return <Box ref={containerRef}>{children}</Box>;
};

export default Sticky;
