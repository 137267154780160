import { createSlice } from "@reduxjs/toolkit";
import { createCandidate, updateCandidate } from "./candidateActions";
import { RootState } from "../../app/store";
import {
  CandidateParams,
  CandidateResponse,
  CandidateShowResponse,
} from "./candidateApi";
import filterErrorMessage from "../../utils/filterErrorMessage";

export interface initialState {
  initialValues: CandidateParams;
  candidate: CandidateResponse | CandidateShowResponse | null;
  isSaving: boolean;
  isSaveSuccess: boolean;
  isError: boolean;
  errorMessage: string | null;
}

export const candidateSlice = createSlice({
  name: "candidate",
  initialState: {
    candidate: null,
    isSaving: false,
    isSaveSuccess: false,
    isError: false,
    errorMessage: null,
  } as initialState,

  reducers: {
    clearState: (state) => {
      state.isSaving = false;
      state.isSaveSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createCandidate.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(createCandidate.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.candidate = payload;
    });
    builder.addCase(createCandidate.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as CandidateResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(updateCandidate.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(updateCandidate.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.candidate = payload;
    });
    builder.addCase(updateCandidate.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as CandidateResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });
  },
});

export const { clearState } = candidateSlice.actions;

export const candidateSelector = (state: RootState) => state.candidate;
