import { createSlice } from "@reduxjs/toolkit";
import {
  createProfile,
  destroyProfile,
  getProfiles,
  showProfile,
  updateProfile,
} from "./profileActions";
import { RootState } from "../../app/store";
import {
  ProfileParams,
  ProfileResponse,
  ProfileShowResponse,
  ProfileIndexAttributes,
} from "./profileApi";
import filterErrorMessage from "../../utils/filterErrorMessage";

export interface initialState {
  initialValues: ProfileParams;
  profile: ProfileResponse | ProfileShowResponse | null;
  profiles: ProfileIndexAttributes[];
  isFetching: boolean;
  isSaving: boolean;
  isFinding: boolean;
  isDestroying: boolean;
  isSuccess: boolean;
  isSaveSuccess: boolean;
  isFindingSuccess: boolean;
  isDestroyingSuccess: boolean;
  isError: boolean;
  errorMessage: string | null;
}

export const initialProfileValues: ProfileParams = {
  id: null,
  name: "",
  permission_ids: [],
};

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    initialValues: initialProfileValues,
    profile: null,
    profiles: [],
    isFetching: false,
    isSaving: false,
    isFinding: false,
    isDestroying: false,
    isSuccess: false,
    isSaveSuccess: false,
    isFindingSuccess: false,
    isDestroyingSuccess: false,
    isError: false,
    errorMessage: null,
  } as initialState,

  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.isSaving = false;
      state.isFinding = false;
      state.isDestroying = false;
      state.isSuccess = false;
      state.isSaveSuccess = false;
      state.isFindingSuccess = false;
      state.isDestroyingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
    clearShowState: (state) => {
      state.profile = null;
      state.isFindingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
    clearDestroyState: (state) => {
      state.profile = null;
      state.isDestroyingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getProfiles.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getProfiles.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.profiles = payload.records;
    });
    builder.addCase(getProfiles.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.profiles = [];
    });

    builder.addCase(createProfile.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(createProfile.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.profile = payload;
    });
    builder.addCase(createProfile.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as ProfileResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(showProfile.pending, (state) => {
      state.isFinding = true;
      state.isFindingSuccess = false;
    });
    builder.addCase(showProfile.fulfilled, (state, { payload }) => {
      state.isFinding = false;
      state.isFindingSuccess = true;
      state.profile = payload;
    });
    builder.addCase(showProfile.rejected, (state, action) => {
      state.isFinding = false;
      state.isError = true;
      const payload = action.payload as ProfileResponse;
      state.errorMessage = filterErrorMessage(
        payload?.error || payload?.error_messages?.[0]
      );
    });

    builder.addCase(updateProfile.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.profile = payload;
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as ProfileResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(destroyProfile.pending, (state) => {
      state.isDestroying = true;
      state.isDestroyingSuccess = false;
    });
    builder.addCase(destroyProfile.fulfilled, (state) => {
      state.isDestroying = false;
      state.isDestroyingSuccess = true;
    });
    builder.addCase(destroyProfile.rejected, (state, action) => {
      state.isDestroying = false;
      state.isError = true;
      const payload = action.payload as ProfileResponse;
      state.errorMessage = filterErrorMessage(
        payload?.error || payload?.error_messages?.[0]
      );
    });
  },
});

export const { clearState, clearShowState, clearDestroyState } =
  profileSlice.actions;

export const profileSelector = (state: RootState) => state.profile;
