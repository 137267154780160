import React from "react";
import { Box, Breakpoint, Container, Theme, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import logo from "./../assets/images/logo.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.common.white,
      borderRadius: 20,
      padding: "20px 22px",
      [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 20px) !important",
        margin: "20px 10px 0",
        maxWidth: "unset !important",
      },
    },
  })
);

function LoginCardContainer({
  children,
  maxWidth = "xs",
}: {
  children: React.ReactNode;
  maxWidth?: Breakpoint | false;
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box height="100vh" sx={{ backgroundColor: theme.palette.primary.main }}>
      <Box sx={{ pt: 8 }}>
        <Container maxWidth={maxWidth} className={classes.container}>
          <Box justifyContent="center" sx={{ display: "flex", mb: 3 }}>
            <img src={logo} alt="Logo" width="160px" />
          </Box>
          {children}
        </Container>
      </Box>
    </Box>
  );
}

export default LoginCardContainer;
