import React, { useState } from "react";
import { AddCircle, Delete, Edit, Save } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  Chip,
  Divider,
  Button,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  FormGroup,
  TextField,
  Tooltip,
  FormHelperText,
} from "@mui/material";
import { FormikProps, getIn } from "formik";
import { JobParams } from "../../redux/job/jobApi";
import Select from "../../components/Select";
import { questionTypeOptions } from "../../utils/constants";
import { FormOptions, FormTemplate } from "../../utils/types";
import deepEqual from "../../utils/deepEqual";

const simplifiedRegistration = {
  resume: "required",
  phone_number: "off",
  address: "off",
  avatar: "off",
  educations: "off",
  work_experiences: "off",
  summary: "off",
  cover_letter: "off",
  linkedin: "off",
  instagram: "off",
  tiktok: "off",
  github: "off",
  facebook: "off",
};

const completeRegistration = {
  resume: "required",
  phone_number: "required",
  address: "required",
  avatar: "required",
  educations: "required",
  work_experiences: "required",
  summary: "required",
  cover_letter: "required",
  linkedin: "required",
  instagram: "required",
  tiktok: "required",
  github: "required",
  facebook: "required",
};

const quickRegistration = {
  resume: "required",
  phone_number: "optional",
  address: "optional",
  avatar: "optional",
  educations: "optional",
  work_experiences: "optional",
  summary: "optional",
  cover_letter: "optional",
  linkedin: "optional",
  instagram: "optional",
  tiktok: "optional",
  github: "optional",
  facebook: "optional",
};

interface IJobForms {
  formik: FormikProps<JobParams>;
}

const JobForms: React.FC<IJobForms> = ({ formik }) => {
  const [jobFormsExpanded] = useState<boolean>(true);
  const [openQuestion, setOpenQuestion] = useState<number | false>(false);

  const validateOpenQuestion = () => {
    if (openQuestion === false) {
      return true;
    }

    const question = formik.values.form.questions[openQuestion];
    if (question.name === "") {
      formik.setFieldError(
        `form.questions.${openQuestion}.name`,
        "Campo Obrigatório"
      );
      formik.setFieldTouched(
        `form.questions.${openQuestion}.name`,
        true,
        false
      );
      return false;
    }

    return true;
  };

  const addQuestion = () => {
    const isValid = validateOpenQuestion();

    if (!isValid) {
      return;
    }

    const index = formik.values.form.questions.length;
    formik.setFieldValue(`form.questions.${index}`, {
      type: "short_answer",
      name: "",
      option: "required",
    });
    setOpenQuestion(index);
  };

  const handleSaveQuestion = () => {
    const isValid = validateOpenQuestion();

    if (!isValid) {
      return;
    }

    setOpenQuestion(false);
  };

  const handleOpenQuestion = (index: number) => {
    const isValid = validateOpenQuestion();

    if (!isValid) {
      return;
    }

    setOpenQuestion(index);
  };

  const handleDeleteQuestion = (index: number) => {
    setOpenQuestion(false);

    formik.setFieldValue(
      "form.questions",
      formik.values.form.questions.filter((_, i) => i !== index)
    );
  };

  const changeFormField = (field: string, value: FormOptions) => {
    formik.setFieldValue(field, value);

    if (!field.startsWith("form.questions")) {
      const fieldName = field.replace("form.fields.", "");

      if (
        deepEqual(
          { ...formik.values.form.fields, [fieldName]: value },
          simplifiedRegistration
        )
      ) {
        formik.setFieldValue("form.template", "simplified_registration");
      } else if (
        deepEqual(
          { ...formik.values.form.fields, [fieldName]: value },
          completeRegistration
        )
      ) {
        formik.setFieldValue("form.template", "complete_registration");
      } else if (
        deepEqual(
          { ...formik.values.form.fields, [fieldName]: value },
          quickRegistration
        )
      ) {
        formik.setFieldValue("form.template", "quick_registration");
      } else {
        formik.setFieldValue("form.template", "custom");
      }
    }
  };

  const changeFormOptions = (template: FormTemplate) => {
    switch (template) {
      case "simplified_registration":
        formik.setFieldValue("form", {
          template: "simplified_registration",
          fields: simplifiedRegistration,
          questions: formik.values.form.questions,
        });
        return;
      case "complete_registration":
        formik.setFieldValue("form", {
          template: "complete_registration",
          fields: completeRegistration,
          questions: formik.values.form.questions,
        });
        return;
      case "quick_registration":
        formik.setFieldValue("form", {
          template: "quick_registration",
          fields: quickRegistration,
          questions: formik.values.form.questions,
        });
        return;
    }
  };

  const renderOptions = (
    field: string,
    value: FormOptions,
    disableOff = false
  ) => {
    return (
      <Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          {!disableOff && (
            <Chip
              label="Desativado"
              onClick={() => changeFormField(field, "off")}
              color={value === "off" ? "primary" : "default"}
            />
          )}
          <Chip
            label="Opcional"
            onClick={() => changeFormField(field, "optional")}
            color={value === "optional" ? "primary" : "default"}
          />
          <Chip
            label="Obrigatório"
            onClick={() => changeFormField(field, "required")}
            color={value === "required" ? "primary" : "default"}
          />
        </Box>
        <FormHelperText error sx={{ textAlign: "right" }}>
          {getIn(formik.errors, field)}
        </FormHelperText>
      </Box>
    );
  };

  const renderFormField = (
    label: string,
    options: JSX.Element,
    disableDivider: boolean = false
  ) => {
    return (
      <>
        {!disableDivider && <Divider />}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my: 1,
            flexWrap: "wrap",
          }}
        >
          <Typography>{label}</Typography>
          <Box sx={{ pr: 2 }}>{options}</Box>
        </Box>
      </>
    );
  };

  return (
    <Accordion expanded={jobFormsExpanded}>
      <AccordionSummary sx={{ cursor: "auto !important" }}>
        <Typography fontWeight={"bold"}>Formulário</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 2,
              mb: 2,
            }}
          >
            <Typography fontWeight={"bold"}>Informações pessoais</Typography>
            <Box>
              <FormControl
                sx={{ width: 220 }}
                error={
                  !!(
                    getIn(formik.touched, "form.template") &&
                    getIn(formik.errors, "form.template")
                  )
                }
              >
                <Select
                  labelId="template-label"
                  id="template"
                  label="Template"
                  size="small"
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    formik.setFieldValue("form.template", e.target.value);
                    changeFormOptions(e.target.value as FormTemplate);
                  }}
                  onBlur={formik.handleBlur("form.template")}
                  value={formik.values.form.template}
                  helperText={
                    getIn(formik.touched, "form.template") &&
                    getIn(formik.errors, "form.template")
                      ? getIn(formik.errors, "form.template")
                      : null
                  }
                >
                  <MenuItem value={"simplified_registration"}>
                    Cadastro Simplificado
                  </MenuItem>
                  <MenuItem value={"complete_registration"}>
                    Cadastro Completo
                  </MenuItem>
                  <MenuItem value={"quick_registration"}>
                    Cadastro Rápido
                  </MenuItem>
                  <MenuItem value={"custom"}>Personalizado</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 1,
            }}
          >
            <Typography>Nome</Typography>
            <Box sx={{ pr: 2 }}>
              <Chip label="Obrigatório" color="primary" />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 1,
            }}
          >
            <Typography>E-mail</Typography>
            <Box sx={{ pr: 2 }}>
              <Chip label="Obrigatório" color="primary" />
            </Box>
          </Box>

          {renderFormField(
            "Anexo do Currículo",
            renderOptions(
              "form.fields.resume",
              formik.values.form.fields.resume
            )
          )}

          {renderFormField(
            "Foto",
            renderOptions(
              "form.fields.avatar",
              formik.values.form.fields.avatar
            )
          )}

          {renderFormField(
            "Telefone",
            renderOptions(
              "form.fields.phone_number",
              formik.values.form.fields.phone_number
            )
          )}

          {renderFormField(
            "Endereço",
            renderOptions(
              "form.fields.address",
              formik.values.form.fields.address
            )
          )}

          <Typography fontWeight={"bold"} sx={{ mt: 4 }}>
            Perfil
          </Typography>

          {renderFormField(
            "Educação",
            renderOptions(
              "form.fields.educations",
              formik.values.form.fields.educations
            ),
            true
          )}

          {renderFormField(
            "Experiência Profissional",
            renderOptions(
              "form.fields.work_experiences",
              formik.values.form.fields.work_experiences
            )
          )}

          {renderFormField(
            "Sumário Profissional",
            renderOptions(
              "form.fields.summary",
              formik.values.form.fields.summary
            )
          )}

          {renderFormField(
            "Carta de Apresentação",
            renderOptions(
              "form.fields.cover_letter",
              formik.values.form.fields.cover_letter
            )
          )}

          <Typography fontWeight={"bold"} sx={{ mt: 4 }}>
            Mídias sociais
          </Typography>

          {renderFormField(
            "LinkedIn",
            renderOptions(
              "form.fields.linkedin",
              formik.values.form.fields.linkedin
            ),
            true
          )}

          {renderFormField(
            "Instagram",
            renderOptions(
              "form.fields.instagram",
              formik.values.form.fields.instagram
            )
          )}

          {renderFormField(
            "Tiktok",
            renderOptions(
              "form.fields.tiktok",
              formik.values.form.fields.tiktok
            )
          )}

          {renderFormField(
            "Github",
            renderOptions(
              "form.fields.github",
              formik.values.form.fields.github
            )
          )}

          {renderFormField(
            "Facebook",
            renderOptions(
              "form.fields.facebook",
              formik.values.form.fields.facebook
            )
          )}

          <Typography fontWeight={"bold"} sx={{ mt: 4 }}>
            Perguntas adicionais
          </Typography>
          <Box sx={{ mt: 1 }}>
            {formik.values.form.questions.map((question, index) => (
              <Box
                key={index}
                sx={{
                  py: 1.5,
                  px: { xs: 1, sm: 2 },
                  mb: 1,
                  backgroundColor: (theme) => theme.palette.grey[100],
                  borderRadius: 1,
                  border: (theme) =>
                    !!(
                      getIn(formik.touched, `form.questions.${index}.type`) &&
                      getIn(formik.errors, `form.questions.${index}.type`)
                    ) ||
                    !!(
                      getIn(formik.touched, `form.questions.${index}.name`) &&
                      getIn(formik.errors, `form.questions.${index}.name`)
                    ) ||
                    !!(
                      getIn(formik.touched, `form.questions.${index}.option`) &&
                      getIn(formik.errors, `form.questions.${index}.option`)
                    )
                      ? `1px solid ${theme.palette.error.main}`
                      : null,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 1,
                  }}
                >
                  <Box sx={{ display: "flex", gap: 2 }}>
                    {openQuestion !== index ? (
                      <>
                        <Tooltip title="Atualizar">
                          <Edit
                            sx={{
                              color: (theme) => theme.palette.grey[600],
                              cursor: "pointer",
                            }}
                            onClick={() => handleOpenQuestion(index)}
                          />
                        </Tooltip>
                        <Tooltip title="Excluir">
                          <Delete
                            sx={{
                              mr: 1,
                              color: (theme) => theme.palette.grey[600],
                              cursor: "pointer",
                            }}
                            onClick={() => handleDeleteQuestion(index)}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <Tooltip title="Salvar">
                          <Save
                            sx={{
                              color: (theme) => theme.palette.grey[600],
                              cursor: "pointer",
                            }}
                            onClick={() => handleSaveQuestion()}
                          />
                        </Tooltip>
                        <Tooltip title="Excluir">
                          <Delete
                            sx={{
                              color: (theme) => theme.palette.grey[600],
                              cursor: "pointer",
                            }}
                            onClick={() => handleDeleteQuestion(index)}
                          />
                        </Tooltip>
                      </>
                    )}
                    <Typography>
                      {getIn(formik.values, `form.questions.${index}.name`)}
                    </Typography>
                  </Box>
                  <Box>
                    {renderOptions(
                      `form.questions.${index}.option`,
                      getIn(formik.values, `form.questions.${index}.option`),
                      true
                    )}
                  </Box>
                </Box>
                {openQuestion === index && (
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <FormGroup>
                      <FormControl
                        fullWidth
                        error={
                          !!(
                            getIn(
                              formik.touched,
                              `form.questions.${index}.type`
                            ) &&
                            getIn(formik.errors, `form.questions.${index}.type`)
                          )
                        }
                        required
                      >
                        <Select
                          labelId="question-type-label"
                          id="question-type"
                          label="Tipo"
                          size="small"
                          onChange={(e: SelectChangeEvent<unknown>) => {
                            formik.setFieldValue(
                              `form.questions.${index}.type`,
                              e.target.value
                            );
                          }}
                          onBlur={formik.handleBlur(
                            `form.questions.${index}.type`
                          )}
                          value={question.type || ""}
                          helperText={
                            getIn(
                              formik.touched,
                              `form.questions.${index}.type`
                            ) &&
                            getIn(formik.errors, `form.questions.${index}.type`)
                              ? getIn(
                                  formik.errors,
                                  `form.questions.${index}.type`
                                )
                              : null
                          }
                        >
                          {questionTypeOptions.map((questionTypeOption) => (
                            <MenuItem
                              key={questionTypeOption.id}
                              value={questionTypeOption.id}
                            >
                              {questionTypeOption.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormGroup>

                    <FormGroup sx={{ mt: 2 }}>
                      <FormControl fullWidth>
                        <TextField
                          type="text"
                          label="Questão"
                          size="small"
                          multiline
                          maxRows={3}
                          onChange={(e) => {
                            formik.setFieldValue(
                              `form.questions.${index}.name`,
                              e.target.value
                            );
                          }}
                          onBlur={formik.handleBlur(
                            `form.questions.${index}.name`
                          )}
                          value={question.name || ""}
                          error={
                            !!(
                              getIn(
                                formik.touched,
                                `form.questions.${index}.name`
                              ) &&
                              getIn(
                                formik.errors,
                                `form.questions.${index}.name`
                              )
                            )
                          }
                          helperText={
                            getIn(
                              formik.touched,
                              `form.questions.${index}.name`
                            ) &&
                            getIn(formik.errors, `form.questions.${index}.name`)
                              ? getIn(
                                  formik.errors,
                                  `form.questions.${index}.name`
                                )
                              : null
                          }
                          sx={{
                            "& .MuiInputBase-root": {
                              backgroundColor: (theme) =>
                                theme.palette.common.white,
                            },
                          }}
                        />
                      </FormControl>
                    </FormGroup>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
          <Button
            startIcon={<AddCircle />}
            sx={{ mt: 1 }}
            onClick={() => addQuestion()}
          >
            Adicionar Questão
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default JobForms;
