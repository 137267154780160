import { AxiosResponse } from "axios";
import requestHelper from "../../utils/requestHelper";

export interface Address {
  address_1: string;
  district: string;
  city: string;
  state: string;
}

const index = async (state: string): Promise<AxiosResponse<string[]>> => {
  return await requestHelper.get(`cities/${state}`).catch((e) => e.response);
};

const getCepInfo = async (cep: string): Promise<AxiosResponse<Address>> => {
  return await requestHelper.get(`cities/cep/${cep}`).catch((e) => e.response);
};

export { index, getCepInfo };
