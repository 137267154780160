import React from "react";
import { Box } from "@mui/material";

interface IBanner {
  src?: string;
}

const Banner: React.FC<IBanner> = ({ src }) => {
  return src ? (
    <Box width="100%" height="30vw">
      <img
        src={src}
        width="100%"
        height="100%"
        style={{ objectFit: "cover", objectPosition: "center center" }}
      />
    </Box>
  ) : null;
};

export default Banner;
