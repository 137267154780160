import React, { useMemo, useState } from "react";
import {
  AddCircle,
  Delete,
  DragIndicator,
  Edit,
  Lock,
  Save,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  Button,
  Tooltip,
  FormGroup,
  FormControl,
  TextField,
} from "@mui/material";
import { FormikProps, getIn } from "formik";
import { JobParams } from "../../redux/job/jobApi";
import {
  DragDropContext,
  Droppable,
  Draggable,
  OnDragEndResponder,
} from "react-beautiful-dnd";

interface IJobProcess {
  formik: FormikProps<JobParams>;
}

const JobProcess: React.FC<IJobProcess> = ({ formik }) => {
  const [openProcess, setOpenProcess] = useState<number | false>(false);

  const validateOpenProcess = () => {
    if (openProcess === false) {
      return true;
    }

    const process = formik.values.job_pipelines_attributes[openProcess];
    if (process.name === "") {
      formik.setFieldError(
        `job_pipelines_attributes.${openProcess}.name`,
        "Campo Obrigatório"
      );
      formik.setFieldTouched(
        `job_pipelines_attributes.${openProcess}.name`,
        true,
        false
      );
      return false;
    }

    return true;
  };

  const addProcess = () => {
    const isValid = validateOpenProcess();

    if (!isValid) {
      return;
    }

    const order = formik.values.job_pipelines_attributes.filter(
      (pipeline) => !pipeline.static
    ).length;

    formik.setFieldValue(
      "job_pipelines_attributes",
      [
        ...formik.values.job_pipelines_attributes,
        {
          id: null,
          name: "",
          order,
          _destroy: false,
        },
      ].sort((a, b) => a.order - b.order)
    );

    setOpenProcess(order + 1);

    focusInput();
  };

  const handleSaveProcess = () => {
    const isValid = validateOpenProcess();

    if (!isValid) {
      return;
    }

    setOpenProcess(false);
  };

  const handleOpenProcess = (index: number) => {
    const isValid = validateOpenProcess();

    if (!isValid) {
      return;
    }

    setOpenProcess(index);

    focusInput();
  };

  const handleDeleteProcess = (index: number) => {
    setOpenProcess(false);

    formik.setFieldValue(`job_pipelines_attributes.${index}._destroy`, true);
  };

  const focusInput = () => {
    setTimeout(() => {
      const inputElement = document.querySelector(
        ".draggable-wrapper.is-open input"
      ) as HTMLInputElement;
      inputElement?.focus();
    }, 0);
  };

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) {
      return;
    }

    const values = Array.from(formik.values.job_pipelines_attributes);

    const staticItems = values.filter((item) => item.static);
    const draggableItems = values.filter((item) => !item.static);

    const [removed] = draggableItems.splice(result.source.index - 1, 1);
    draggableItems.splice(
      result.destination.index === 0 ? 0 : result.destination.index - 1,
      0,
      removed
    );

    const reorderedValues = [
      staticItems[0],
      ...draggableItems,
      ...staticItems.splice(1, 2),
    ].map((value, index) => ({
      ...value,
      order: value.static ? value.order : index - 1,
    }));

    formik.setFieldValue("job_pipelines_attributes", reorderedValues);
  };

  const pipelines = useMemo(() => {
    return [...formik.values.job_pipelines_attributes].sort(
      (a, b) => a.order - b.order
    );
  }, [formik.values.job_pipelines_attributes]);

  return (
    <Accordion expanded>
      <AccordionSummary sx={{ cursor: "auto !important" }}>
        <Typography fontWeight={"bold"}>Funil de Recrutamento</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="job-pipeline">
              {(provided, snapshot) => (
                <Box
                  sx={{ mt: 1 }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {pipelines.map((pipeline, index) => {
                    if (pipeline?._destroy) {
                      return;
                    }

                    return (
                      <Draggable
                        key={pipeline.id || `new-${index}`}
                        draggableId={pipeline.id?.toString() || `new-${index}`}
                        index={index}
                        isDragDisabled={pipeline.static}
                      >
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            className={`draggable-wrapper ${
                              openProcess === index && "is-open"
                            }`}
                            sx={{
                              py: 1.5,
                              px: { xs: 1, sm: 2 },
                              mb: 1,
                              backgroundColor: (theme) =>
                                theme.palette.grey[100],
                              borderRadius: 1,
                              border: (theme) =>
                                snapshot.isDragging
                                  ? `1px solid ${theme.palette.primary.main}`
                                  : getIn(
                                      formik.touched,
                                      `job_pipelines_attributes.${index}.name`
                                    ) &&
                                    getIn(
                                      formik.errors,
                                      `job_pipelines_attributes.${index}.name`
                                    )
                                  ? `1px solid ${theme.palette.error.main}`
                                  : null,
                            }}
                            {...provided.draggableProps}
                            {...(pipeline.static
                              ? null
                              : provided.dragHandleProps)}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                              }}
                            >
                              {pipeline.static ? (
                                <Tooltip title="Trancado">
                                  <Lock
                                    sx={{
                                      color: (theme) => theme.palette.grey[600],
                                      mb: 0.5,
                                    }}
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Arrastar">
                                  <DragIndicator
                                    sx={{
                                      color: (theme) => theme.palette.grey[600],
                                    }}
                                  />
                                </Tooltip>
                              )}
                              <Box
                                sx={{
                                  display:
                                    openProcess !== index ? "flex" : "none",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                {!pipeline.static && (
                                  <>
                                    <Tooltip title="Atualizar">
                                      <Edit
                                        sx={{
                                          color: (theme) =>
                                            theme.palette.grey[600],
                                          cursor: "pointer",
                                        }}
                                        onClick={() => handleOpenProcess(index)}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Excluir">
                                      <Delete
                                        sx={{
                                          mr: 1,
                                          color: (theme) =>
                                            theme.palette.grey[600],
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleDeleteProcess(index)
                                        }
                                      />
                                    </Tooltip>
                                  </>
                                )}
                                <Typography sx={{ py: "7.3px" }}>
                                  {pipeline.name}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display:
                                    openProcess !== index ? "none" : "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <Tooltip title="Salvar">
                                  <Save
                                    sx={{
                                      color: (theme) => theme.palette.grey[600],
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleSaveProcess()}
                                  />
                                </Tooltip>
                                <Tooltip title="Excluir">
                                  <Delete
                                    sx={{
                                      color: (theme) => theme.palette.grey[600],
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleDeleteProcess(index)}
                                  />
                                </Tooltip>
                                <FormGroup>
                                  <FormControl fullWidth>
                                    <TextField
                                      type="text"
                                      size="small"
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `job_pipelines_attributes.${index}.name`,
                                          e.target.value
                                        );
                                      }}
                                      onBlur={formik.handleBlur(
                                        `job_pipelines_attributes.${index}.name`
                                      )}
                                      value={pipeline.name || ""}
                                      error={
                                        !!(
                                          getIn(
                                            formik.touched,
                                            `job_pipelines_attributes.${index}.name`
                                          ) &&
                                          getIn(
                                            formik.errors,
                                            `job_pipelines_attributes.${index}.name`
                                          )
                                        )
                                      }
                                      helperText={
                                        getIn(
                                          formik.touched,
                                          `job_pipelines_attributes.${index}.name`
                                        ) &&
                                        getIn(
                                          formik.errors,
                                          `job_pipelines_attributes.${index}.name`
                                        )
                                          ? getIn(
                                              formik.errors,
                                              `job_pipelines_attributes.${index}.name`
                                            )
                                          : null
                                      }
                                      sx={{
                                        "& .MuiInputBase-root": {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.white,
                                        },
                                      }}
                                      onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                          handleSaveProcess();
                                        }
                                      }}
                                    />
                                  </FormControl>
                                </FormGroup>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            startIcon={<AddCircle />}
            sx={{ mt: 1 }}
            onClick={() => addProcess()}
          >
            Adicionar
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default JobProcess;
