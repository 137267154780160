import React, { useEffect } from "react";
import { Box, Button, Container, colors } from "@mui/material";
import logo from "./../../assets/images/logo.png";
import { Google, LinkedIn } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { googleSignInUrl, linkedinSignInUrl } from "../../utils/oauth";
import { toast } from "react-toastify";
import { saveToken } from "../../utils/localStorage";
import urls from "../../utils/urls";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const access_token = queryParams.get("access_token");
  const refresh_token = queryParams.get("refresh_token");
  const return_url = queryParams.get("return_url");
  const error = queryParams.get("error");

  useEffect(() => {
    handleLogin();
  }, [access_token, refresh_token, return_url]);

  useEffect(() => {
    if (error) {
      toast.error("Não foi possível completar o login, tente novamente!");
    }
  }, [error]);

  const handleLogin = async () => {
    if (access_token && refresh_token) {
      await saveToken({
        access_token,
        refresh_token,
      });
      if (return_url) {
        navigate(return_url);
      } else {
        navigate(urls.adminCandidateJobs);
      }
    }
  };

  return (
    <Box height="100vh" sx={{ backgroundColor: colors.grey[100] }}>
      <Box sx={{ pt: 8 }}>
        <Container maxWidth="sm">
          <Box justifyContent="center" sx={{ display: "flex", mb: 12 }}>
            <img src={logo} alt="Logo" width="160px" />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box sx={{ width: "100%", maxWidth: 300 }}>
              <Link to={googleSignInUrl(return_url)}>
                <Button
                  variant="outlined"
                  size="large"
                  startIcon={<Google />}
                  sx={{ width: "100%" }}
                >
                  Continuar com Google
                </Button>
              </Link>
            </Box>

            <Box sx={{ width: "100%", maxWidth: 300 }}>
              <Link to={linkedinSignInUrl(return_url)}>
                <Button
                  variant="outlined"
                  size="large"
                  startIcon={<LinkedIn />}
                  sx={{ width: "100%" }}
                >
                  Continuar com LinkedIn
                </Button>
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Login;
